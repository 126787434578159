import React from 'react'
import axios from 'axios'
//import Switch from 'react-switch'
import ReactGA from 'react-ga'
import moment from 'moment'

import DateTimePicker from '../Components/DateTimePicker'
import OrderResume from '../Components/OrderResume'

import IosWarningOutline from 'react-ionicons/lib/IosWarningOutline'
import IosTrashOutline from 'react-ionicons/lib/IosTrashOutline'
import IosInformationCircle from 'react-ionicons/lib/IosInformationCircle'

import { ScaleLoader } from 'react-spinners';

import complete_order from '../files/images/emp_commande_cours_complete.png'

import { formatAddress, formatDate, getTime, renderOptions, durationToString, pretty_hour } from '../functions/functions'

import "../css/orderer.css"

export default class Orderer extends React.Component{
	constructor(props){
		super(props)
		this.state={
			showHelp: false,
			showPicker: false,
			showResume: false,
			showConfirmation: false,
			date: null,
			duration: 1,
			orderSmall: "",
			teacher: null,
			teachers: 0,
			remote: false,
			profile: null,
			regularEnd: null,
			warningCpt: 0,
			wrongFields: {},
			helpStyle: {div: {maxWidth: '35vh', position: 'absolute', left: 20, padding: '5px', top: '12vh'}, img: {height: '30vh', width: '35vh'}},
		}
	}

	componentDidMount = () => {
		this.getLevels()
		this.getStatistics()
		if(this.props.preset){
			this.setState({
				level: this.props.preset.level,
				topic: this.props.preset.topic,
				address: this.props.userData.address,
			})
		}

	}

	getTeachers = () => {
		if (!this.state.topic || !this.state.level) return
		const headers = {'Content-Type' : 'application/json',
		'key': 'hello',
		'id':this.props.userData._id,
		'token': this.props.userData.token}
		axios.get(this.props.apiAddress+"/availableTeachers?level="+this.state.level._id+"&topic="+this.state.topic._id, {headers: headers})
		.then((res) =>{
			this.setState({availableTeachers: true, teachers: res.data, teacher: res.data[0]})
		}).catch((err) => {
			console.log(err)
		})
	}

	getLevels = () => {
		axios.get("https://api.expliquemoipythagore.fr/v2/public/levels")
		.then((doc) => {
			this.setState({levels: doc.data})
			console.log("heyhey")
		})
	}

	searchThroughLevels = () => {
		console.log("heyheyheyhey")
		let res = []
		this.state.levels.forEach((item, i) => {
			console.log(item.name, item.name.search(this.state.q), this.state.q)
			if(item.name.search(this.state.q) !== -1){
				res.push({name: item.name, price: item.price})
				console.log({name: item.name, price: item.price})
			}
		});
		this.setState({res: res})

	}

	getSelect(element, event){
		const index = event.target.value
		// if(!this.props.userData.addresses){
		// 	return
		// }
		if(index === '1000')
		{//happens when it's the predefined topic/level that is choosen
		if(element==="level"){
			this.setState({[element]:this.props.preset.level})
		}else if(element==="topic"){
			this.setState({[element]:this.props.preset.topic})
		}else if(element==="address"){
			this.setState({[element]:this.props.userData.address, remote: false})
		}else if(element==="profile"){
			this.setState({[element]: undefined})
		}
	}else if(index === '1001' && element === "address"){
		this.setState({remote: true})
	}else if (index === '1001' && element==="teacher"){
		this.setState({[element]: null})
	}else if(element === "level"){
		this.setState({level: this.props.utility.levels[index]}, () =>
		{if (this.state.subject !== 'none'){
			this.setState({levelField: 'form-control is-valid'})
		}else{
			this.setState({levelField: 'form-control is-invalid'})
		}
		this.setState({availableTeachers: false, teachers: []})//reset the available teachers research
	}
)
}else if(element === "topic"){
	this.setState({topic: this.props.utility.topics[index]}, () =>{
		if (this.state.subject !== 'none'){
			this.setState({topicField: 'form-control is-valid'})
		}else{
			this.setState({topicField: 'form-control is-invalid'})
		}
		this.setState({availableTeachers: false, teachers: []})//reset the available teachers research
	}
)
}else if(element === "address"){
	this.setState({[element]: this.props.userData.addresses[index]}, () =>{
		if (this.state.address !== 'none'){
			this.setState({addressField: 'form-control is-valid', remote: false})
		}else{
			this.setState({addressField: 'form-control is-invalid', remote: false})
		}
	}
)
}else if(element === "teacher"){
	if(index === this.state.teachers.length)
	this.setState({teacher: null})
	this.setState({[element]: this.state.teachers[index]}, () =>{
		if (this.state.teacher !== 'none' ){
			this.setState({teacherField: 'form-control is-valid'})
		}else{
			this.setState({teacherField: 'form-control is-invalid'})
		}
	}
)
}else if(element === "profile" && index !== '100000'){
	this.setState({[element]: this.props.profiles[index],
		level: this.props.profiles[index].level
	}
)
}
if (element === 'topic' || element === 'level') {
	setTimeout(this.getTeachers, 300)
}
this.checkOrder()

}

onChange = (date) => {
	this.setState({ date })
	this.checkOrder()
}

onChangeQuery = (e) => {
	this.setState({ q: e.target.value },  () => this.searchThroughLevels())
}

createOrder = () => {
	const order = {
		remote: this.state.remote,
		type: "order",
		topic: this.state.topic,
		level: this.state.level,
		date: this.state.date,
		duration: this.state.duration,
		createdBy: this.props.userData._id,
		createdOn: new Date(),
		address: this.state.address,
		status: "ordered",
		preferredTeacher: (this.state.teacher !== null && this.state.teacher !== undefined) ? this.state.teacher._id: this.state.teacher,
		profile: this.state.profile,
		regularEnd: this.state.regularEnd,
		orderedFrom: "website",
	}
	const checking = this.checkOrder(order, true)

	if(checking === "wrong hour"){
		this.setState({orderSmall: "Heure du cours dépassée"})
	}else if(checking){
		this.setState(
			{showResume: !this.state.showResume,
				order: order,
				orderSmall: "",
			})
		}else{
			this.setState({orderSmall: "Veuillez remplir tous les champs"})
		}

	}

	checkOrder = (order, beforeSend=false) => {
		//appelée après certaines modificationsde l'objet this;state.orders. Before send est mis à true si c'est la dernière vérification avant l'affichage du résumé
		let wrongs = {}
		for (let prop in order){
			//console.log(prop, order[prop], wrongs)
			//exclusion des champs 'preferredTeacher' et 'regularEnd', et 'address' qui est traité à part;    cas où la valeur est considérée invalide

			if((prop !== 'preferredTeacher' && prop !== "regularEnd" && prop !== 'address') && (order[prop] === undefined || order[prop] === null)) {
				wrongs[prop] = true
				if(beforeSend){
					ReactGA.event({
						category: 'Course',
						action: "failed (field '" + prop +"' missing)"
					})
					this.setState({warningCpt: this.state.warningCpt + 1})
				}
			}else if(prop === 'address'){
				if(!this.state.remote){	//on ne vérifie l'adresse que si ce n'est pas un cours à distance
				if(order[prop] === undefined || order[prop].number === ''){
					wrongs.address = true
					if(beforeSend){
						ReactGA.event({
							category: 'Course',
							action: "failed (field 'address' missing)"
						})
						this.setState({warningCpt: this.state.warningCpt + 1})
					}
				}else{
					wrongs.address = false
				}
			}
		}else{
			wrongs[prop] = false
		}
	}
	this.setState({wrongFields: wrongs})
	for(let p in wrongs) if(wrongs[p]) return false

	if(beforeSend && new Date(order.date) < new Date()){
		ReactGA.event({
			category: 'Course',
			action: "failed (antidated course)"
		})
		return "wrong hour"
	}

	return true
}


sendOrder = (prec) => {
	this.setState({showResume: !this.state.showResume, showLoader: true})
	let order = this.state.order
	order["precisions"] = prec
	console.log(order)
	const headers = {'Content-Type' : 'application/json',
	'key': 'hello',
	'id':this.props.userData._id,
	'authorization': this.props.auth.token}
	axios.post(this.props.apiAddress+'/v2/orders/',
	this.state.order,
	{ headers: headers })
	.then(() => {
		this.setState({orderSucceed: true})
		this.setState({showConfirmation: true, showLoader: false})
		ReactGA.event({
			category: 'Course',
			action: 'ordered'
		})
	})
	.catch((err) => {
		console.log(err)
		alert("Erreur lors de la commande:", err)
		this.setState({orderSucceed: false})
		this.setState({showConfirmation: false, showLoader: false})
		ReactGA.event({
			category: 'Course',
			action: 'failed (post failed)'
		})
	})
}

addressesStyle = () => {
	if(!this.state.remote && this.state.wrongFields.address){
		return "btn lg orderer-select outline yellow invalid"
	}else if(this.state.remote || (this.props.userData.address && this.props.userData.address.number)){
		return "btn lg orderer-select blue"
	}else{
		return "btn lg orderer-select outline yellow"
	}
}

profileStyle = () => {
	if(this.state.wrongFields.profile || this.state.wrongFields.level){
		return "btn lg orderer-select yellow invalid"
	}else if(!this.props.profiles){
		return "btn lg orderer-select outline yellow"
	}else if(this.props.profiles.length > 0 && this.state.profile){
		return "btn lg orderer-select blue"
	}else{
		return "btn lg orderer-select yellow"
	}
}

topicStyle = () => {
	if(this.state.wrongFields.topic){
		return "btn lg orderer-select yellow invalid"
	}else if(this.props.preset.topic || this.state.topic){
		return "btn blue lg orderer-select"
	}else{
		return "btn yellow lg orderer-select"
	}

}

dateStyle = () => {
	if(this.state.wrongFields.date){
		return "btn yellow lg orderer-select invalid"
	}else if(this.state.date){
		return "btn blue lg orderer-select"
	}else{
		return "btn yellow lg orderer-select"
	}
}


logUlule = async () => {
	const headers = {'Content-Type' : 'application/json',
	'key': 'hello',
	'id':this.props.userData._id,
	'authorization': this.props.auth.token}
	try {
		await axios.get(this.props.apiAddress+'/v2/estallesurulule', { headers});
	} catch (err) {
		console.log('Error logging ulule')
	}
}

showBillionsOfWarnings = () => {
	let warnings = []
	if(this.state.warningCpt > 2 && !this.state.showHelp){
		this.setState({showHelp: true})
	}
	for(let i=0; i<this.state.warningCpt; i++){
		warnings.push(<p>{this.state.orderSmall}</p>)
	}
	return <div style={{color: 'red'}}>{warnings}</div>
}


help = () => {
	return <div
		className="bg-white frame-only column"
		onMouseOver={() => this.setState({helpStyle: { img: {height: '45vh', width: '50vh'}, div :{maxWidth: '50vh', position: 'absolute', left: 20, padding: '5px', top: '12vh'}}})}
		onMouseLeave={() => this.setState({helpStyle: { img: {height: '30vh', width: '35vh'}, div :{maxWidth: '35vh', position: 'absolute', left: 20, padding: '5px', top: '12vh'}}})}
		style={this.state.helpStyle.div}>
		<button className="btn sm outline link yellow full-width" onClick={() => this.setState({showHelp: false, warningCpt: 0})}>Fermer</button>
		<p>Vous semblez rencontrer un problème.. Assurez-vous que tous les boutons sont en bleu, comme montré ci dessous</p>
		<img src={complete_order} style={this.state.helpStyle.img} alt="exemple"/>
		<p>Si le problème n'est pas là, veuillez nous envoyer un mail avec si possible une capture d'écran afin de nous aider à comprendre l'origine du problème</p>
	</div>
}


getStatistics(){
	console.log('allo')

	const headers = {'Content-Type' : 'application/json',
	'key': 'hello',
	'id': this.props.auth.email,
	'authorization': this.props.auth.token}
	axios.get(this.props.apiAddress+"/v2/statistics/time-study", {headers: headers})
	.then((doc) =>{
		const avgs = { noPrefTeacher_avg: new Date(doc.data.noPrefTeacher_avg), withPrefTeacher_avg: new Date(doc.data.withPrefTeacher_avg)}
		this.setState({ avgs })
	}).catch((err) => {
		console.log(err)
	})
}

renderConfirmation = () => {
	return(
		<div className="center rounded column frame-only" style={{textAlign: 'center', padding: '5%', position: "absolute", top: '15%', height: '70vh', backgroundColor: 'white', zIndex: "1000"}}>
			Votre cours a été commandé !<br/>
			{this.state.teacher?
				<>La demande a été transmise à votre professeur.</>
			:
			<React.Fragment>
				<p> La demande a été transmise à nos professeurs.</p>
				{/*<p className='italic'>En moyenne, la première proposition arrive en {pretty_hour(this.state.avgs.noPrefTeacher_avg, false, true)}</p>*/}
			</React.Fragment>
			}
			<br/>

			<br/>
			<button
				className="btn blue lg"
				onClick={() => this.setState({showConfirmation: false})}>
				Fermer
			</button>
		</div>
	)
}

render(){
	return(
		<React.Fragment>
			{this.state.showHelp && this.help()}
			{/*}<input type="text" onChange={this.onChangeQuery} value={this.state.q}/>*/}
			{this.state.res?.map.map((item, i) => <p key={i}>{item.name} prix: {item.price}</p>)}
			{this.state.showLoader &&
				<div className="center rounded column frame-only" style={{textAlign: 'center', padding: '5%', position: "absolute", top: '15%', height: '70vh', backgroundColor: 'white', zIndex: "1000"}}>
					{this.state.teacher?
						<>Envoi de la demande de cours à votre professeur...</>
					:
					<>Envoi de la demande de cours à nos professeurs...</>
					}
					<br/>
					<br/>
					<ScaleLoader
						className={' loader'}
						sizeUnit={"px"}
						height={30}
						width={5}
						color={'#4080ff'}
						loading={true}
					/>
					<br/>
				</div>
			}
			{this.state.showConfirmation && this.renderConfirmation()	}
			<div className="container"  style={{textAlign: 'center'}}>
				<div style={{height: '28px'}}></div>
				<select className={this.addressesStyle()} id="address" onChange={(e) => this.getSelect("address",e)}>
					<option key={1000} value={1000}>
						{this.props.userData.address && this.props.userData.address.number? formatAddress(this.props.userData.address):"À domicile"}
					</option>
					<option key={1001} value={1001} >
						À distance
					</option>
					{this.props.userData.addresses.length > 0?
						renderOptions(this.props.userData.addresses, "addresses")
					:
					<option key={100000} disabled>Ajoutez une adresse dans votre profil</option>
					}
				</select>
				<select className={this.profileStyle()} id="profile"  onChange={(e) => this.getSelect("profile",e)}>
					<option key={1000} value={1000}>
						Profil
					</option>
					{this.props.profiles && this.props.profiles.length > 0?
						renderOptions(this.props.profiles, "profiles")
					:
					<option key={100000} disabled value={100000}>Ajoutez un profil dans vos informations</option>
					}
				</select>
				<select className={this.topicStyle()} id="topic"  onChange={(e) => this.getSelect("topic",e)}>
					<option key={1000} value={1000}>
						{this.props.preset.topic? this.props.preset.topic.name:"Matière"}
					</option>
					{renderOptions(this.props.utility.topics)}
				</select>
				<button
					className={this.dateStyle()}
					onClick={() => this.setState({showPicker: !this.state.showPicker})}>
					{!this.state.date? 'Date & Heure':formatDate(this.state.date, "string")}
					&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
					{!this.state.date? 'Durée':durationToString(this.state.duration)}
				</button>
				{this.state.regularEnd &&
					<small className= "space-between full-width">tous les {moment(new Date(this.state.date)).format('dddd')} à {getTime(this.state.date, "string")}<br/> jusqu'au {formatDate(this.state.regularEnd, "short")}
						<button
							className="btn link sm"
							onClick={() => this.setState({regularEnd: undefined})}>
							<IosTrashOutline
								fontSize="2em"
							color="#f00"/>
						</button>
					</small>}

				{this.state.availableTeachers && this.state.teachers.length >0 && this.state.level && this.state.topic?
					<div id="preferredTeacher-button">
						<select className="btn blue lg orderer-select" id="teacher"  onChange={(e) => this.getSelect("teacher",e)}>
							{renderOptions(this.state.teachers, "teachers")}
							<option key={1001} value={1001}>Aucun</option>
						</select>
						<span className="orderer-tooltip">Cliquez pour choisir un ancien professeur</span>
					</div>
				:
					!this.state.availableTeachers? this.getTeachers():null
				}

				{this.state.showPicker?
					<DateTimePicker
						date={this.state.date? this.state.date:undefined}
						giveDate={(date) => this.setState({date: date})}
						giveEndDate={(d) => this.setState({regularEnd: d})}
						giveDuration={(duration) => this.setState({duration: duration})}
						toggle={() => this.setState({showPicker: !this.state.showPicker})}
						withRecurrent={this.state.teachers.length > 0 }
					/>
				:
						null
				}
				{this.state.showResume &&
					<OrderResume
						order={{order: this.state.order}}
						hasFreeOrder={this.props.userData.freeOrder}
						subscription={this.props.userData.subscription}
						teacher={this.state.teacher? this.state.teacher.firstname+" "+this.state.teacher.lastname : null}
						sendOrder={prec => this.sendOrder(prec)}
						end_sub={this.props.userData.end_sub}
						toggle={() => this.setState({showResume: !this.state.showResume})}/>
				}

				<div><p id="orderer-price">{this.state.level ? ((this.state.remote? this.state.level.remotePrice:this.state.level.price) * this.state.duration) : " -- "}€</p></div>
				{this.showBillionsOfWarnings()}
				<div>
					<button
						id='orderer-btn'
						className='btn green lg'
						onClick={this.createOrder}>
						Continuer
					</button>
					{/*this.state.orderSmall && <p>{this.state.orderSmall}</p>*/}
					<br/>
					<small style={{overflowX: "wrap", maxWidth:"10em"}}>
						<IosInformationCircle
							fontSize="1.4em"
							color="#fa0"
							style={{position: 'relative', top: 4}}
						/>&nbsp;Les cours sont à régler au professeur <br/>à la fin de la séance.
						Vous pourrez effectuer<br/> la déclaration sur le site de la&nbsp;
						<a className="btn link" style={{marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0}} href="https://www.cesu.urssaf.fr/info/accueil.html" target="_blank" rel="noopener noreferrer">CESU</a>
						&nbsp;pour une<br/> déduction d'impôt.
					</small>
				</div>
				{false && <p className="column" style={{backgroundColor: "#FF9", height: "15vh", padding: "10px"}}>
					<IosWarningOutline
						fontSize="4em"
					color="#000"/>
				</p>}
			</div>
		</React.Fragment>
				)
			}
		}
