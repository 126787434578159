import React from 'react'
import Rating from 'react-rating'
import axios from 'axios'

import full from '../files/images/svg/full-star.svg'
import empty from '../files/images/svg/empty-star.svg'
import '../css/rateTeacher.css'

const style={image: {padding: '1px', height: '2.5em'}}

export default class RateTeacher extends React.Component{
	constructor(props){
		super(props)
		this.state={general: 3,
					pres: 3,
					mind: 3,
					patience: 3,
					peda: 3,
					run:3,
					text: "",
					}
	}

	getRates(rate, id){
		this.setState({[id]: rate})
	}

	sendRates(){
		const data = {general: this.state.general,
					  presentation: this.state.pres,
					  mind: this.state.mind,
					  patience: this.state.patience,
					  pedago: this.state.peda,
					  course: this.state.run,
					  text: this.state.text,
					 }

		const headers = {'Content-Type' : 'application/json',
						 'key': 'hello',
						 'token': this.props.auth.token,
						 'id': this.props.auth.email}
		axios.post(this.props.apiAddress+"/teacher/"+this.props.course.teacher+"/rate", 
			{rates: data, order_id: this.props.course._id}, 
			{headers: headers})
		.then((res) => {
			console.log(res)
			alert("Merci pour votre retour!")
			this.props.rated()
		})
		.catch((err) => console.log(err))
	}

	handleChange(event) {
    	this.setState({text: event.target.value});
  	}

  	small = (rate) => {
  		if(rate === 1){
  			return <p className="rater-small">Très insatisfaisant</p>
  		}else if(rate === 2){
  			return <p className="rater-small">Insatisfaisant</p>
  		}else if(rate === 3){
  			return <p className="rater-small">Bien</p>
  		}else if(rate === 4){
  			return <p className="rater-small">Très bien</p>
  		}else if(rate === 5){
  			return <p className="rater-small">Parfait</p>
  		}
  	}

	render(){
		return(
			<div className="frame rater">
				<p className="rater-title">Evaluation</p>
				<p className="rater-indication">Ces notes ne seront pas publiques, sont anonymes et seule l'équipe EMP y a accès.</p>
				<br/>
				<div className="criteria">
					<div className="title">
						<p className="main">Avis Général:</p>
						<p className="secondary"> votre satisfaction globale par rapport à la prestation de notre professeur.</p>
					</div>
					<div className="rate">
					<Rating onChange={(rate) => this.getRates(rate, "general")}
							initialRating={this.state.general} 
							fullSymbol={<img alt='' src={full}   style={style.image} className="icon" />}
							emptySymbol={<img alt='' src={empty}  style={style.image}	 className="icon" />}/>
					{this.small(this.state.general)}
					</div>
					<br/>
				</div>
				<div className="criteria">
					<div className="title">
						<p className="main">Présentation: </p>
						<p className="secondary">le professeur a-t-il été correct ?</p>
					</div>
					<div className="rate">
					<Rating onChange={(rate) => this.getRates(rate, "pres")} initialRating={this.state.pres} 
							fullSymbol={<img alt='' src={full}   style={style.image} className="icon" />}
							emptySymbol={<img alt='' src={empty}  style={style.image}	 className="icon" />}/>
					{this.small(this.state.pres)}
				</div>
				</div>
				<div className="criteria">
					<div className="title">
						<p className="main">Etat d'esprit:</p>
						<p className="secondary">votre satisfaction globale concernant vos différents échanges avec notre professeur.</p>
					</div>
					<div className="rate">
					<Rating onChange={(rate) => this.getRates(rate, "mind")} 
							initialRating={this.state.mind} 
							fullSymbol={<img alt='' src={full}   style={style.image} className="icon" />}
							emptySymbol={<img alt='' src={empty}  style={style.image}	 className="icon" />}/>
					{this.small(this.state.mind)}
					</div>
				</div>
				<div className="criteria">
					<div className="title">
						<p className="main">Patience: </p>
						<p className="secondary">notre professeur a-t-il été patient avec à votre enfant ?</p>
					</div>
					<div className="rate">
					<Rating onChange={(rate) => this.getRates(rate, "patience")} 
							initialRating={this.state.patience} 
							fullSymbol={<img alt='' src={full}   style={style.image} className="icon" />}
							emptySymbol={<img alt='' src={empty}  style={style.image}	 className="icon" />}/>
					{this.small(this.state.patience)}
					</div>
				</div>
				<div className="criteria">
					<div className="title">
						<p className="main">Pédagogie: </p>
						<p className="secondary">notre professeur s'est il fait comprendre dans sa manière d'expliquer le cours ?</p>
					</div>
					<div className="rate">
					<Rating onChange={(rate) => this.getRates(rate, "peda")} 
							initialRating={this.state.peda} 
							fullSymbol={<img alt='' src={full}   style={style.image} className="icon" />}
							emptySymbol={<img alt='' src={empty}  style={style.image}	 className="icon" />}/>
					{this.small(this.state.peda)}
					</div>
				</div>
				<div className="criteria">
					<div className="title">
						<p className="main">Déroulement: </p>
						<p className="secondary">votre satisfaction globale concernant le déroulement du cours.</p>
					</div>
					<div className="rate">
					<Rating onChange={(rate) => this.getRates(rate, "run")} 
							initialRating={this.state.run} 
							fullSymbol={<img alt='' src={full}   style={style.image} className="icon" />}
							emptySymbol={<img alt='' src={empty}  style={style.image}	 className="icon" />}/>
					{this.small(this.state.run)}
					</div>
				</div>
				
				{/*<div class="form-group">
				    <label for="exampleFormControlTextarea1">Commentaire</label>
				    <br/>
				    <textarea class="form-control" onChange={(e) => this.handleChange(e)} rows="3" value={this.state.text}></textarea>
				</div>*/}
				<button type="button" className="btn link green" onClick={() => this.sendRates()}>Envoyer</button>&nbsp;
				<br/>
				<br/>
				<br/>
			</div>
		)
	}
}