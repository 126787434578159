import React from 'react'
import axios from 'axios'
import Peer from 'simple-peer'
import {
  withRouter,
} from 'react-router-dom'
import ReactGA from 'react-ga'
import { getCookie, getTime } from '../functions/functions.js'
import io from 'socket.io-client';
import fileDownload from 'js-file-download';

import MdSend from 'react-ionicons/lib/MdSend'
import MdDownload from 'react-ionicons/lib/MdDownload'
import IosMicOutline from 'react-ionicons/lib/IosMicOutline'
import IosMicOffOutline from 'react-ionicons/lib/IosMicOffOutline'
import IosVideocamOutline from 'react-ionicons/lib/IosVideocamOutline'
import IosLaptopOutline from 'react-ionicons/lib/IosLaptop'

import '../css/visio.css'

import logo from '../files/images/logo.png'

/* commented to avoid warnings
function randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}
*/

class Visio extends React.Component{
  constructor(props){
    super(props)
    console.log('props', props)
    this.state = {
      orderId: props.match.params.orderId,
      id: getCookie('user'),
      status: 'Initializing connection...',
      token: getCookie('token'),
      messages: [],
      message: '',
      majPressed: false,
      files: [],
      showFiles: false,
      audio: true,
      video: true,
      shareScreen: false,
    }
  }

  componentDidMount() {
    this.setState({ status: 'Temporizing...' })
    this.fetchOrderAndInitStream()
    ReactGA.pageview("Visio");
  }

  componentDidUpdate() {
    const objDiv = document.getElementById("messagesList");
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  fetchOrderAndInitStream = async () => {
    try {
      const headers = {
        'Content-Type' : 'application/json',
        'Authorization': this.state.token,
        'id': this.state.id,
      }
      const { data } = await axios.get(`${this.props.apiAddress}/course/${this.state.orderId}`, { headers });
      this.setState({ order: data }, this.initStream)
      console.log(data)
    } catch (err) {
      this.setState({ status: 'Still alone, retrying...' })
    }
  }

  initSocket = () => {
    const socket = io(this.props.apiAddress)
    this.setState({ socket })
    socket.emit('hello', {
      relation: `${this.state.order.teacher}-${this.state.order.createdBy}`,
      user: this.state.id,
    })

    socket.on('messages', (messages) => {
      this.setState({ messages });
      this.scrollToBottom("messagesList")
    })

    socket.on('disconnection', async () => {
      console.log('Other left')
      this.state.peer.destroy()
      socket.close()
      this.initStream()
    })
    this.setState({ socket })
  }

  getBuiltInStream = async (options) => {
    if (this.state.builtInStream) {
      return this.state.builtInStream;
    }
    try {
      let stream = await new Promise(async (resolve, reject) => {
        navigator.getUserMedia = ( navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);
        if (!navigator.getUserMedia && navigator.mediaDevices.getUserMedia) {
          const stream = await navigator.mediaDevices.getUserMedia(options)
          return resolve(stream);
        } else if (navigator.getUserMedia) {
          navigator.getUserMedia(
            options,
            (stream) => resolve(stream),
            (err) => reject("Error getting user media " + err)
          )
        } else {
          return reject(Error("Unsupported"))
        }
      })
      this.setState({ builtInStream: stream });
      return stream;
    } catch (err) {
      alert("Votre navigateur ne supporte pas le protocole d'échange par vidéo. Merci de mettre à jour votre navigateur.");
    }
  }

  getShareScreenStream = async () => {
    if (this.state.shareScreenStream) {
      return this.state.shareScreenStream;
    }
    const stream = await navigator.mediaDevices.getDisplayMedia();
    this.setState({ shareScreenStream: stream });
    return stream;
  }

  initStream = async (options={ video: true, audio: true }) => {
    try {
      const stream = await this.getBuiltInStream(options);
      this.initConnection(stream);
    } catch (err) {
      alert("Votre navigateur ne supporte pas le protocole d'échange par vidéo. Merci de mettre à jour votre navigateur.");
    }
  }

  initiator = async (stream) => {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream: stream
    })
    peer.on('signal', async (data) => {
      const headers = {
        'Content-Type' : 'application/json',
        'Authorization': this.state.token,
      }
      try {
        await axios.delete(`${this.props.apiAddress}/v2/orders/${this.state.orderId}/visio`, { headers });
      } catch(err) {
        console.log('Visio did not exist before');
      }
      try {
        await axios.post(`${this.props.apiAddress}/v2/orders/${this.state.orderId}/visio/offer`, data, { headers });
      } catch(err) {
        console.log('Response error', err)
      }
    })
    this.setState({ status: 'Waiting for the other...' })
    let interval = setInterval(async () => {
      console.log('Waiting other...')
      try {
        const headers = {
          'Content-Type' : 'application/json',
          'Authorization': this.state.token,
                }
        const { data } = await axios.get(`${this.props.apiAddress}/v2/orders/${this.state.orderId}/visio/answer`, { headers });
        this.setState({ status: 'Connecting to the other...' })
        peer.signal(data.data)
        clearInterval(interval)
      } catch (err) {
        this.setState({ status: 'Still alone, retrying...' })
      }
    }, 2000)
    this.setState({ status: 'Peer created...' });
    return peer;
  }

  answerer = async (stream) => {
    const headers = {
      'Content-Type' : 'application/json',
      'Authorization': this.state.token,
    }
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream: stream
    })
    peer.on('signal', async (data) => {
      try {
        await axios.post(`${this.props.apiAddress}/v2/orders/${this.state.orderId}/visio/answer`, data, { headers });
      } catch(err) {
        console.log('Response error', err)
      }
    })
    let waitOfferInterval = setInterval(async () => {
      try {
        const { data } = await axios.get(`${this.props.apiAddress}/v2/orders/${this.state.orderId}/visio/offer`, { headers });
        peer.signal(data.data)
        this.setState({ status: 'Signaling to the initiator...' })
        clearInterval(waitOfferInterval);
      } catch (err) {
        console.error('Could not get the offer', err)
      }
    }, 2000)
    return peer;
  }

  initConnection = async (stream) => {
    console.log('initing connection')
    this.backVideo.srcObject = this.state.builtInStream;
    //const headers = { 'Authorization': this.state.token }
    let peer = null;
    console.log(this.state.order.teacher, this.state.id)
    if (this.state.order.teacher === this.state.id) {
      peer = await this.initiator(this.state.builtInStream)
    } else {
      peer = await this.answerer(this.state.builtInStream)
    }
    console.log('peer', peer)
    this.setState({ peer })
    peer.on('connect', () => {
      this.initSocket()
      this.setState({ status: 'Connected !' })
    })

    peer.on('stream', (stream) => {
      this.video.srcObject = stream
      this.setState({ status: 'Streaming !' })
    })

    peer.on('error', async (error) => {
      console.log('Peer error', error)
      peer.destroy()
      if (this.state.socket) this.state.socket.close()
      this.initStream()
    })

  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) { //ENTER KEY
      this.sendMessage()
    }
  }

  sendMessage = () => {
    if (!this.state.socket || this.state.message.trim() === '') return
    this.state.socket.emit('message', {
      user: this.state.id,
      order: this.state.orderId,
      message: this.state.message
    })
    /*animateScroll.scrollTo(550, {
      containerId: "messagesList"
    });*/
    this.setState({ message: '' })
    this.scrollToBottom("messagesList")
  }

  scrollToBottom = (id) => {
   var div = document.getElementById(id);
   div.scrollTop = div.scrollHeight - div.clientHeight;
  }

  getMessageStyle = (message) => {
    const base = {
      maxWidth: 200,
      padding: 10,
      borderRadius: '15px',
      textAlign: 'left',
      overflowWrap: 'break-word',
    }
    return (message.user === this.state.id ? {
      ...base,
      color: 'white',
      backgroundColor: '#4080ff',
    } : {
      ...base,
      color: 'black',
      backgroundColor: '#ddd',
    })
  }

  getMessageBoxStyle = (message) => {
    const base = {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    }
    return (message.user === this.state.id ? {
      ...base,
      justifyContent: 'flex-end',
    } : {
      ...base,
      justifyContent: 'flex-start',
    })
  }

  sendFile = async (file) => {
    try {
      if (file.size > 5 * 1024 * 1024) { // 5MB
        alert('Vous ne pouvez pas envoyer de fichier de plus de 5Mo')
        return
      }
      const data = new FormData()
      data.append('file', file)
      const headers = { 'Authorization': this.state.token }
      await axios.post(`${this.props.apiAddress}/v2/orders/${this.state.orderId}/visio/files`, data, { headers })
      alert('Fichier envoyé avec succès !')
    } catch (err) {
      console.error('Error uploading file', err)
      alert('Erreur d\'envoi du fichier', err.toString())
    }
  }

  getFiles = async () => {
    const headers = { 'Authorization': this.state.token, 'Accept': 'application/json' }
    const res = await axios.get(`${this.props.apiAddress}/v2/orders/${this.state.orderId}/visio/files`, { headers })
    let files = res.data
    files = files.map(f => {
      let tokens = f.split('-')
      return { date: new Date(parseInt(tokens.shift(), 10)), name: tokens.join('-') }
    })
    files = files.sort((a, b) => a.name.localeCompare(b.name))
    this.setState({ files: files })
    this.setState({showFiles: true})
  }

  downloadFile = async (file) => {
    let fullname = `${file.date.getTime()}-${file.name}`
    const headers = { 'Authorization': this.state.token, 'Accept': 'application/json' }
    const res = await axios.get(`${this.props.apiAddress}/v2/orders/${this.state.orderId}/visio/files/${fullname}`, { headers, responseType: 'blob' } )
    fileDownload(new Blob([res.data]), file.name);
  }

  toggleAudio = () => {
    console.log('toggling audio')
    this.state.builtInStream.getTracks().forEach((track) => {
      if (track.kind === 'audio') {
        track.enabled = !track.enabled;
        this.setState({ audio: track.enabled })
      }
    });
  }

  toggleVideo = () => {
    this.state.builtInStream.getTracks().forEach((track) => {
      console.log('track kind', track.kind)
      if (track.kind === 'video') {
        track.enabled = !track.enabled;
      }
    });
    if (this.state.shareScreenStream) {
      this.state.shareScreenStream.getTracks().forEach((track) => {
        console.log('track kind', track.kind)
        if (track.kind === 'video') {
          track.enabled = !track.enabled;
        }
      });
    }
    this.setState((state) => ({ video: !state.video }));
  }

  shareScreen = async () => {
    if (!this.state.shareScreen) {
      let stream = await this.getShareScreenStream();
      stream.getTracks().forEach((newTrack) => {
        if (newTrack.kind === 'video') {
          this.state.builtInStream.getTracks().forEach((oldTrack) => {
            if (oldTrack.kind === 'video') {
              this.state.peer.replaceTrack(oldTrack, newTrack, this.state.builtInStream)
              this.backVideo.srcObject = stream
              this.setState({ shareScreen: true })
            }
          });
        }
      });
    } else {
      let stream = await this.getBuiltInStream();
      stream.getTracks().forEach((newTrack) => {
        if (newTrack.kind === 'video') {
          this.state.shareScreenStream.getTracks().forEach((oldTrack) => {
            if (oldTrack.kind === 'video') {
              this.state.peer.replaceTrack(oldTrack, newTrack, this.state.builtInStream)
              this.backVideo.srcObject = stream
              this.setState({ shareScreen: false })
            }
          });
        }
      });
    }

  }

  render(){
    return(
      <React.Fragment>
      <div className="visio-header">
        EMP - Visio&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {this.state.order ?
          <p>Fin du cours: {(parseInt(getTime(this.state.order.date).hour)+this.state.order.duration)+":"+getTime(this.state.order.date).minute}</p>
        : null }
      </div>
      <div className="visio">

        {this.state.showFiles ?
          <div id="files-modal">
            <p>Fichiers</p>
            <hr/>
            <ul>
            {this.state.files.map(f => (
                <li key={f.date.toISOString()} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p>
                    {f.name}
                  </p>
                  <p>{f.date.toLocaleString()}</p>
                  <MdDownload
                        className="button"
                        fontSize="1.5em"
                        color="#4080ff"
                        onClick={() => this.downloadFile(f)}/>
                </li>
              ))}
            </ul>
            <button className="btn blue sm" type='button' onClick={() => this.setState({showFiles: false} )} value='closeFiles' name='Fermer'>Fermer</button>
        </div>
        :
        null
        }

        <div style={styles.messages}>
            <div id='messagesList' style={styles.messagesList}>
              <ul>
                {this.state.messages.map(m =>
                  <li key={m.date.toString()} style={this.getMessageBoxStyle(m)}>
                    <p style={this.getMessageStyle(m)}>{m.text}</p>
                  </li>)}
              </ul>
            </div>
            <div className="input-panel">
              <div className="input-line">
                <input className="input" placeholder="Aa" type='text' name='message' value={this.state.message} onKeyDown={this.handleKeyDown} onChange={(e) => this.setState({ message: e.target.value })} />

                <div style={styles.sendBtn}>
                  <MdSend
                      className="button"
                      fontSize="1.5em"
                      onClick={this.sendMessage} value='Envoyer' name='Envoyer'
                      color="#4080ff"/>
                </div>
              </div>
              {/*<p>Status : {this.state.status}</p>*/}

              <div className="row flex-start">
                <label htmlFor="file" className="btn blue sm">Envoyer un fichier</label>
                <input className="input-file" id="file" type="file" name="file" onChange={(e) => this.sendFile(e.target.files[0])}/>
                <button className="btn blue sm" type='button' onClick={this.getFiles} value='sendFile' name='Voir les fichiers'>Voir les fichiers</button>
              </div>
            </div>


        </div>
        <div className="video-container">
          <video
            id="video"
            ref={video => this.video = video}
            controls autoPlay
            style={styles.video}
            width={window.innerWidth *  3/4}
            height={window.innerWidth * 0.95 * (3/4) / (4/3)}
            mutedss
          />
          <video
            muted
            id="backVideo"
            ref={video => this.backVideo = video}
            autoPlay style={styles.backVideo}
            width={window.innerWidth * 1/4}
            height={window.innerWidth * (1/4) / (4/3)}
          />
          {this.state.builtInStream && <div style={styles.toggleAudio} type='button' onClick={this.toggleAudio}>{this.state.audio ? <IosMicOutline/> : <IosMicOffOutline/>}</div>}
          {this.state.builtInStream && <div style={styles.toggleVideo} type='button' onClick={this.toggleVideo}>
            <IosVideocamOutline/>
            {!this.state.video && <div style={{ position: 'absolute', width: '0.5px', height: '60%', right: '53%', backgroundColor: 'black', transform: 'rotate(-30deg)'}}/>}
          </div>}
          {this.state.builtInStream && <div style={styles.toggleShareScreen} type='button' onClick={this.shareScreen}>
            <IosLaptopOutline/>
            {!this.state.shareScreen && <div style={{ position: 'absolute', width: '0.5px', height: '60%', right: '53%', backgroundColor: 'black', transform: 'rotate(-30deg)'}}/>}
          </div>}
        </div>
      </div>
      </React.Fragment>

      )
  }
}

export default withRouter(Visio)

const styles = {
  video: {
    position: 'absolute',
    left: 0,
    top: '3vh',
    maxHeight: '97vh'
  },
  backVideo:{
    position: 'absolute',
    right: 0,
    top: '3vh',
  },
  toggleAudio:{
    position: 'absolute',
    top: window.innerWidth * (1/4) / (4/3) - 50,
    right: window.innerWidth * 1/4 / 2 + 25,
    width: 40,
    height: 40,
    backgroundColor: '#ccc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 40,
  },
  toggleVideo:{
    position: 'absolute',
    top: window.innerWidth * (1/4) / (4/3) - 50,
    right: window.innerWidth * 1/4 / 2 - 20,
    width: 40,
    height: 40,
    backgroundColor: '#ccc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 40,
  },
  toggleShareScreen: {
    position: 'absolute',
    top: window.innerWidth * (1/4) / (4/3) - 50,
    right: window.innerWidth * 1/4 / 2 - 65,
    width: 40,
    height: 40,
    backgroundColor: '#ccc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 40,
  },
  sendBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '-30px',
    zIndex: '15',
  },
  messages: {
    display: 'flex',
    width: window.innerWidth * 1/4,
    height: window.innerHeight - (window.innerWidth * (1/4) / (4/3)) + 'px',
    position: 'absolute',
    right: 0,
    top: (window.innerWidth * (1/4) / (4/3)) + 'px',
    marginBottom: 0,
    backgroundImage: `url(${logo})`,
    backgroundSize: '150px 150px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'rgba(245,245,245,0.8)',
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center',
  },
  messagesList:{
    width: '100%',
                                //       hauteur du retour video             hauteur de l'input pannel
    height: window.innerHeight - (window.innerWidth * (1/4) / (4/3)) - window.innerHeight/100*15 + 'px',
    display: 'flex',
    flexDirection: 'column-reverse',
    overflowY: 'auto',
  }
}
