import React from 'react'

import DateTimePicker from '../DateTimePicker'

import MdSearch from 'react-ionicons/lib/MdSearch'

/*
* props needed
* course: course Object
* userDate: userData object
* parentState: state of the parentState
* setParentState: link to setState of parent
* fpatch: link to patch function
* fcancel: link to cancel funciton
*/

export default function BCOrdered(props){
    if(props.userType === "pupil"){
        return(
            <div className="ordered specific">
                <p>Nous recherchons un professeur...</p>
                <MdSearch
                    fontSize="100px"
                    beat
                    color="#aaa"
                />
                <p>Cela peut prendre quelques jours</p>
                <button className="btn red rounded" onClick={()=> props.fcancel(props.course._id)}>Annuler le cours</button>
            </div>
        )
    }else{
        return(
            <div className="ordered specific">
                <p>En attente de la réponse du parent/élève</p>
                <button className="btn red rounded" onClick={()=> props.fcancel(props.course._id)}>Annuler le cours</button>
            </div>
        )
    }
}
