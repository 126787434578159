import React from 'react'
import axios from 'axios'
import moment from 'moment'
import ReactGA from 'react-ga'

import MdSearch from 'react-ionicons/lib/MdSearch'
import MdClose from 'react-ionicons/lib/MdClose'
import IosRepeat from 'react-ionicons/lib/IosRepeat'

import OrderCard from '../Components/OrderCard'
import RateTeacher from '../Components/RateTeacher'
import RatePupil from '../Components/RatePupil'
import DateTimePicker from '../Components/DateTimePicker'
import BCCancelled from '../Components/BigCoursesComponents/BCCancelled'
import BCConfirmed from '../Components/BigCoursesComponents/BCConfirmed'
import BCOrdered from '../Components/BigCoursesComponents/BCOrdered'


import { ScaleLoader } from 'react-spinners';

import '../css/courses.css'


import { getTime, formatDate, add_days, add_minutes } from '../functions/functions'

import sand_glass from '../files/images/sand_glass.png'
import defaultAvatar from '../files/images/Missing_avatar.png'

import tuto_visio_site from '../files/tutos/Tuto_acceder_a_la_visio_par_le_site.pdf'


import 'moment/locale/fr'
moment.locale('fr')

export default class Courses extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			expand: false,
			activeCourseIndex: 0,
			filter: 'none',
			interlocutor: {},
		}
		ReactGA.pageview("courses("+this.props.userData.type+")")
	}


	onChange(event){
		this.setState({[event.target.name]: event.target.value})
	}

	componentDidMount = () => {
		const cursor = document.getElementById('bg-today-cursor')
		if(cursor) cursor.scrollIntoView({behavior: 'smooth'})
		document.getElementById('bg-today-cursor') && document.getElementById('bg-today-cursor').scrollIntoView({behavior: 'smooth'})
	}

	formatOrder(course){
		let order = {}
		order = Object.assign(order, course)
		order.fulldate = order.date
		order.time = getTime(order.date, "object")
		order.date = formatDate(order.date, "short")
		let status = ""
		if(order.status === "cancelled"){
			status = "Annulé"
			order.color = "red"
		}else if(order.status === "confirmed"){
			status = "À venir"
			order.color = "blue"
		}else if(order.status === "done"){
			status = "Effectué"
			order.color = "green"
		}else if(order.status === "ordered"){
			status = "Commandé"
			order.color = "yellow"
		}
		order.statusName = status
		return order
	}

	getOrderDetail = (course) => {
		if(this.state.currentOrderId === course._id) return
		const headers = {'Content-Type' : 'application/json',
		'key': 'hello',
		'Access-Control-Allow-Origin' : this.props.apiAddress+'/v2/profile',
		'id':this.props.auth.email,
		'authorization': this.props.auth.token,}
		axios.get(`${this.props.apiAddress}/v2/orders/${course._id}`, {headers})
		.then((doc) => {
			const img64 = 'data:image/png;charset=utf-8;base64, '+ doc.data.teacherDetails?.picture
			this.setState(
				{
					orderDetail: doc.data,
					currentOrderId: course._id,
					teacherData: doc.data.teacherDetails,
					pupilData: doc.data.pupil,
					teacherAvatar: img64,
					detailsGet: true,
					visioUrl: doc.data.visioUrl,
				})//, this.renderBigCourse(course, doc.data))
			}
		).catch((err) => {
			console.error(err)
		})
	}

	patchCourse = (id) => {
		this.setState({orderUpToDate: false})
		const headers = {'Content-Type' : 'application/json',
		'key': 'hello',
		'Access-Control-Allow-Origin' : this.props.apiAddress+'/v2/profile',
		'id':this.props.auth.email,
		'authorization': this.props.auth.token,}
		const data = {date: this.state.newDate, duration: this.state.newDuration}
		axios.patch(`${this.props.apiAddress}/v2/orders/${id}/date`, data, {headers})
		.then((doc) => {
			this.setState({orderUpToDate: true})
		}).catch((err) => {
			this.setState({orderUpToDate: true})
			alert("Erreur lors de la modification. Si le problème persiste, contactez nous.")
			console.error(err)
		})
	}

	renderOrders(format="card"){
		let courses = this.filterOrders(this.props.orders)
		const now = new Date()
		let prevDate = 0
		if(format==="buttons"){
			return courses.map((course, i) =>
			<div key={i} onClick={() => this.setState({activeCourseIndex: i})}>
				{/*les cours sont dans le sens anti-chronologique*/}
				{ ( !(new Date(prevDate) > now) && !(now > new Date(course.date))) && <p id="bg-today-cursor">Aujourd'hui, {getTime(now, 'string')}</p>}
				<p style={{display: 'none'}}>{prevDate = course.date}</p>
				<OrderCard
					key={i}
					id={"ordercardbc"+i}
					data={this.formatOrder(course)}
					userData={this.props.userData}
					small
					auth={this.props.auth}
					apiAddress={this.props.apiAddress}
					active={this.state.activeCourseIndex === i}
				/>
			</div>)
		}
		return courses.map((course, i) => <OrderCard key={i} data={this.formatOrder(course)} apiAddress={this.props.apiAddress} userData={this.props.userData}/>)
	}

	filterOrders(courses){
		if(this.state.filter ==='none') return courses
		if(this.state.filterType ==='status'){
			return courses.filter((course) => course.status===this.state.filter)
		}else if(this.state.filterType ==='interlocutor' && this.props.userData.type === "pupil"){
			return courses.filter((course) => course.teacherName===this.state.filter)
		}else if(this.state.filterType ==='interlocutor' && this.props.userData.type === "teacher"){
			return courses.filter((course) => course.pupil===this.state.filter)
		}
	}

	renderStatus(course){
		const status= course.status
		if(status === "ordered")   return <BCOrdered course={course} userType={this.props.userData.type} fcancel={this.cancel.bind(this)}/>
		if(status === "cancelled") return <BCCancelled course={course} userType={this.props.userData.type}/>
		if(status === "confirmed") return(
			<BCConfirmed
				course={course}
				userData={this.props.userData}
				parentState={this.state}
				setParentState={this.setState.bind(this)}
				fpatch={this.patchCourse.bind(this)}
				fcancel={this.cancel.bind(this)}
				apiAddress={this.props.apiAddress}
			/>
		)
		if(status === "done"){
			return(
				<div className="done specific">
					<div className="rater">
						{course.rated?
						"Merci d'avoir noté ce cours!":
						add_days(new Date(course.fulldate), 2) < new Date()?
							<React.Fragment>
								<p>Le délai pour noter ce cours est dépassé</p>
								<img alt="" className="sand_glass reversed" src={sand_glass} />
							</React.Fragment>
						:
							this.props.userData.type === "pupil"?
								<RateTeacher
									apiAddress={this.props.apiAddress}
									auth={this.props.auth}
									rated={this.props.update}
									course={course}
								/>
							:
							<RatePupil
								apiAddress={this.props.apiAddress}
								auth={this.props.auth}
								rated={this.props.update}
								course={course}
							/>
						}
					</div>
				</div>
			)
		}
	}

	getSelect(element, event){
		const index = event.target.value
		if(element === "interlocutor"){
			this.setState({filter: this.props.interlocutorsList[index], filterType: 'interlocutor', activeCourseIndex:0})
		}
	}

cancel(id){
	const headers = {'Content-Type' : 'application/json',
	'key': 'hello',
	'Access-Control-Allow-Origin' : this.props.apiAddress+'/cancel',
	'id': this.props.auth.email,
	'authorization': this.props.auth.token}
	axios.post(this.props.apiAddress+'/cancel',
	{id: id},
	{headers: headers})
	.then((doc) => {
		this.props.update()
	}).catch(function (err) {
		console.log(err);
	});
}

renderDetails(index){
	const courses = this.filterOrders(this.props.orders)
	let course = courses[index]
	if(course){
		course = this.formatOrder(course)
		this.getOrderDetail(course)
		return this.state.detailsGet && this.renderBigCourse(course) // attends que getOrderDetails ait terminé
	}else{
		return <div className="details">Aucun cours pour cette catégorie</div>
	}

}

renderBigCourse = (course, details) => {
	let interlocutorData
	if(course.status === "confirmed" || course.status === "done" || course.status === "cancelled" ){
		interlocutorData = this.props.userData.type === "pupil" ? this.state.teacherData : this.state.pupilData
	}
	const now = new Date()
	const courseDate = new Date(course.fulldate)
	courseDate.setMinutes(courseDate.getMinutes() - 5)
	return (
		<div className="details big-courses">
			<div className="infos column">
				{course.regular?
					<div style={{position: 'relative', top: '0px', right: '0px', alignSelf: 'flex-end', margin: '0px', padding: '0px', marginBottom: '-20px'}}><IosRepeat
						fontSize="2em"
																																							   color="#fa0"/>
					</div>
				:
					null/*<div style={{position: 'relative', top: '0px', right: '0px', alignSelf: 'flex-end', margin: '0px', padding: '0px', marginBottom: '-20px'}}>
					<IosFlame
					fontSize="2em"
					color="#fa0"/>
				</div>*/}
				{course.status === 'confirmed' && now >= courseDate && this.state.visioUrl && <a className="btn blue square" href={this.state.visioUrl} target="_blank" rel="noopener noreferrer">Accéder au cours par webcam</a>}
				{course.remote && course.status === 'confirmed' && now < courseDate && <p style={{fontSize: "0.6em", color:"#557", margin: '0px', textAlign: 'center'}}>Vous pourrez accéder à l'espace visio 5 minutes avant le début du cours</p>}
				{course.remote && <a style={{margin: 0, padding: '0.35em'}} href={tuto_visio_site} target="_blank" rel="noopener noreferrer" className="btn link blue">Tutoriel: Accéder à la visio</a>}
				<p className="item date">{moment(course.fulldate).format('LLLL')}</p>
				<p className="item time">{course.duration}h</p>
				<p className="item level">{course.level.name}</p>
				<p className="item price">{(course.remote ? course.level.remotePrice : course.level.price) * (course.duration || 1)}€</p>
				<p className="item topic">{course.topic.name}</p>
				{!course.remote && <p className="item address1">{course.address?.number}&nbsp;{course.address?.street}</p>}
				{!course.remote && <p className="item address2">{course.address?.zipcode}&nbsp;{course.address?.city}</p>}
				{course.remote && <p className="item address1">À distance</p>}
				{course.precisions ?
					<p className="" style={{fontStyle: 'italic'}}>"{course.precisions}"</p>
				:
				<><br/><small>{this.props.userData.type === "pupil"? "Vous n'avez pas laissé de commentaire":"Pas de commentaire du parent/élève"}</small></>
				}
				{interlocutorData ?
					this.state.teacherAvatar || this.props.userData.type === "teacher"?
						<div className="interlocutorCard">
							<img alt="" className="courses avatar" src={this.state.teacherAvatar && this.props.userData.type === "pupil"? this.state.teacherAvatar:defaultAvatar} />
							<div className="text">
								<p className="title">{this.props.userData.type === "pupil"? "Professeur :":"Élève :"}</p>
								<p className="firstname">{interlocutorData.firstname}</p>
								<p className="lastname">{interlocutorData.lastname}</p>
								{course.status != "cancelled" && <p className="number">{(add_minutes(courseDate, course.duration*60) > new Date()) && interlocutorData.number}</p>}
							</div>
						</div>
					:
					<ScaleLoader
						className={' loader'}
						sizeUnit={"px"}
						height={30}
						width={5}
						color={'#4080ff'}
						loading={true}
					/>
				:
					null
				}
			</div>
			<div className="column">
				{this.renderStatus(course)}
				{/*
					<div>
					<p>Annotation <br/><small>(vous seul y avez accès)</small></p>
					<textarea
					className="text-input teacher-self-comment"
					type="text"
					value={this.state.teacherComment}
					onChange={(e) => this.onChange(e)}
					placeholder={"Ajouter une précision (optionel)"}
					name="teacherComment"/>
					</div>
				*/}
			</div>
		</div>
	)
}

renderInterlocutorFilter = array => {
	return(
		<select id="interlocutorFilter" onChange={(e) => this.getSelect("interlocutor", e)}>
			<option key={1001} value={1001} >
				{this.props.userData.type === "pupil"? "Par professeur":"Par élève"}
			</option>
			{this.props.interlocutorsList.map( (inter, key) => <option key={key} value={key}>{inter}</option>)}
			}
		</select>
	)
}

render(){
	return(
		<React.Fragment>
			<div className="courses expanded">
				<div>
					<div className="courses legend">
						<div className="courses legend item" onClick={() => this.setState({filter: 'none', activeCourseIndex:0})}>
							{this.state.filter==='none'? 'Filtrer:':'✕'}
						</div>
						<div className="courses legend item yellow" onClick={() => this.setState({filter: 'ordered', filterType: 'status', activeCourseIndex:0})}>
							En attente
						</div>
						<div className="courses legend item red" onClick={() => this.setState({filter: 'cancelled', filterType: 'status', activeCourseIndex:0})}>
							Annulé
						</div>
						<div className="courses legend item blue" onClick={() => this.setState({filter: 'confirmed', filterType: 'status', activeCourseIndex:0})}>
							À venir
						</div>
						<div className="courses legend item green" onClick={() => this.setState({filter: 'done', filterType: 'status', activeCourseIndex:0})}>
							Effectué
						</div>
					</div>
					{this.renderInterlocutorFilter()}
					<div className="scroll-y list expanded">
						{this.props.orders?
							this.renderOrders("buttons")
						:
						<ScaleLoader
							className={' loader'}
							sizeUnit={"px"}
							height={30}
							width={5}
							color={'#4080ff'}
							loading={true}
						/>}
					</div>
				</div>
				{this.renderDetails(this.state.activeCourseIndex)}

			</div>
		</React.Fragment>
		)
	}
}
