import React from 'react'
import { formating } from '../functions/functions'

const createHour = (step) => {
	let hours = []
	for(let i=0; i<24; i+=step){
		hours.push(i);
	}
	return hours.map((hour, i) => <option value={formating(hour)} key={i}>{formating(hour)}</option>)
}

const createMinute = (step) => {
	let minutes = []
	for(let i=0; i<60; i+=step){
		minutes.push(i);
	}
	return minutes.map((minute, i) => <option value={formating(minute)} key={i}>{formating(minute)}</option>)
}

const divide = (time) => {
	let t = time
	if(t[4] === undefined){
		t = t[0]+t[1]+t[2]+'0'+t[3]
	}
	return {hour:t[0]+t[1], minute: t[3]+t[4]}
}

const truncateUpper = (minute, step) => {
	console.log("min:",minute)
	if(minute%step === 0&&minute !==60){
		return minute
	}
	let res = parseInt(minute/step) * step +step
	if(res===60){
		console.log(res)
		return 55
	}
	console.log(res)
	return res
}


export default class TimePicker extends React.Component{
	constructor(props){
		super()
		this.state= {
			hour: divide(props.time).hour,
			minute: formating(truncateUpper(divide(props.time).minute, 5)),
			duration: 1,
		}
	}

	selectHour(){
		const e = document.getElementById("hour")
		const value = e.options[e.selectedIndex].value
		this.props.onTimeChange({hour: value, minute: this.state.minute, duration: this.state.duration})
		this.setState({hour: value})
	}

	selectMinute(){
		const e = document.getElementById("minute")
		const value = e.options[e.selectedIndex].value
		this.props.onTimeChange({hour: this.state.hour, minute: value, duration: this.state.duration})
		this.setState({minute: value})
	}

	selectDuration(){
		const e = document.getElementById("duration")
		const value = parseFloat(e.options[e.selectedIndex].value)
		this.props.onTimeChange({hour: this.state.hour, minute: this.state.minute, duration: value})
		this.setState({duration: value})
	}

	render(){
		const initialMinute = formating(truncateUpper(divide(this.props.time).minute, 5))
		return(
		<div className={"time-picker btn lg " + this.props.className}>
			<select className="time-picker-select" onChange={() => this.selectHour()} id="hour">
								<option value={divide(this.props.time).hour}>{divide(this.props.time).hour}</option>
								{createHour(1)}
			</select>
			<div className="input-group-append">
								<label className="input-group-text" htmlFor="inputGroupSelect01">h</label>
			</div>
			<select className="time-picker-select" onChange={() => this.selectMinute()} id="minute">
								<option value={initialMinute}>{initialMinute}</option>
								{createMinute(5)}
			</select>
			&nbsp;&nbsp;|&nbsp;&nbsp;
			<select className="time-picker-select" onChange={() => this.selectDuration()} id="duration">
								<option value={1}>1h</option>
								<option value={1.5}>1h30</option>
								<option value={2}>2h</option>
			</select>
		</div>)}
}
