import React from 'react'
import moment from 'moment'

import DateTimePicker from '../Components/DateTimePicker'


import '../css/recurrentcourse.css'

export default class RecurrentCourse extends React.Component{
    constructor(props){
      super(props)
      this.state = {
        showPicker: false,
        endDate: props.endDate,
      }
    }
    render(){
      const mydate = new Date(this.props.startDate)
      const weekDayName =  moment(mydate).format('dddd');
        return(
            <div className="recurrent-course frame-only bg-white column">
               <p>Prendre un cours tous les {weekDayName} <br/>à {this.props.hour} jusqu'au</p>
               <DateTimePicker
         			  date={this.state.endDate? this.state.endDate:undefined}
         			  giveDate={(endDate) => this.setState({endDate})}
                      style={{a: "date-time-picker no-frame"}}
                      noTime
         							giveDuration={(duration) => this.setState({duration: duration})}/>
              <div className="row">
                <button className="btn yellow lg" id="level" onClick={() => this.props.close()}>
                  	Annuler
                </button>
                {this.state.endDate !== this.props.endDate && (
                  <button className="btn green lg" id="level" onClick={() => this.props.validate(this.state.endDate)}>
            					Valider
            			</button>
                )}
              </div>
            </div>
        )
    }
}
