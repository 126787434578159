import React from 'react'

import MdClose from 'react-ionicons/lib/MdClose'


/*
* props needed
* course: course Object
* userType: type of the user (pupil or teacher)
*
*/

export default function BCCancelled(props){
    let msg
    const course = props.course
    if(course.cancelledBy){
        if(props.userType === "pupil"){
            msg = course.cancelledBy===course.createdBy? "Vous avez annulé ce cours":"Ce cours a été annulé par le professeur."
        }else if(props.userType === "teacher"){
            msg = course.cancelledBy!==course.createdBy? "Vous avez annulé ce cours":"Ce cours a été annulé par le parent/élève."
        }
    }else{
        msg = "Ce cours a expiré avant de trouver un professeur.\n"
        if(course.preferredTeacher) msg = "Le professeur que vous aviez sollicité n'a pas répondu à temps"
    }
    return(
        <div className="cancelled specific">
            <MdClose
                fontSize="100px"
                color="#F00"
            />
            {msg}
        </div>)
    }
