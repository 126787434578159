import React from 'react'
import { isAndroid, isIOS } from 'react-device-detect'

import Login from './Pages/Login.js'
import ResetPassword from './Pages/ResetPassword.js'
import Register from './Pages/Register.js'
import Dashboard from './Pages/Dashboard.js'
import Visio from './Pages/Visio.js'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom"
import ReactGA from 'react-ga';


import './App.css'
import './css/forms.css'
import './css/buttons.css'

import contrat_client from './files/legals/CONTRAT_ELEVE_CLIENT.pdf'
import Rgpd from './files/legals/NOTRE POLITIQUE DE PROTECTION DES DONNÉES À CARACTÈRE PERSONNEL.pdf'

import ArticlePepite from './articles/Article_Prix_PEPITE/prix_national_pepite-des-pepites'

export default class App extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            // apiAddress: "http://localhost:3500"
            apiAddress: "https://api.expliquemoipythagore.fr"
        }
        ReactGA.initialize('UA-127129226-1')
        try {
            window.location.replace('emp://')
            setTimeout(function () {
                if (isIOS) {
                    window.location.replace('https://apps.apple.com/fr/app/explique-moi-pythagore/id1435151757');
                } else if (isAndroid) {
                    window.loaction.replace('https://play.google.com/store/apps/details?id=com.empythagore.app&gl=FR');
                }
            }, 1000);
        } catch (err) {
            console.log('Error', err)
        }
    }

    render(){
        return (
            <div className="App">
                <header className="App-header">
                    <Router>
                        {/* A <Switch> looks through its children <Route>s and
                            renders the first one that matches the current URL. */}
                        <Switch>
                            <Route path="/contrat">
                                <a href = {contrat_client}>Ouvrir le contrat (pdf)</a>
                            </Route>
                            <Route path="/rgpd">
                                <a href={Rgpd}>Ouvrir le document (pdf)</a>
                            </Route>
                            <Route path="/cgu">
                                <a href={Rgpd}>Ouvrir le document (pdf)</a>
                            </Route>
                            <Route path="/login">
                                <Login />
                            </Route>
                            <Route path="/register">
                                <Register apiAddress={this.state.apiAddress}/>
                            </Route>
                            <Route path="/reset-password/:token">
                                <ResetPassword apiAddress={this.state.apiAddress}/>
                            </Route>
                            <Route path="/dashboard/*">
                                <Dashboard apiAddress={this.state.apiAddress}/>
                            </Route>
                            <Route path="/dashboard">
                                <Dashboard apiAddress={this.state.apiAddress}/>
                            </Route>
                            <Route path="/visio/:orderId">
                                <Visio apiAddress={this.state.apiAddress}/>
                            </Route>
                            <Route path="/articles/pepite-des-pepites">
                                <ArticlePepite />
                            </Route>
                            <Route path="">
                                <Dashboard apiAddress={this.state.apiAddress}/>
                            </Route>

                        </Switch>
                    </Router>
                </header>
            </div>
        );
    }
}
