import React from 'react'

import DateTimePicker from '../DateTimePicker'


/*
* props needed
* course: course Object
* userDate: userData object
* parentState: state of the parentState
* setParentState: link to setState of parent
* fpatch: link to patch function
* fcancel: link to cancel funciton
*/

export default function BCConfirmed(props){
    return(
        <div className="confirmed specific">
            {props.userData.type === "teacher" && props.parentState.teacherData?.badges?.includes('vip') &&
            <React.Fragment>
                {props.parentState.showPicker &&
                    <React.Fragment>
                        <DateTimePicker
                            date={props.parentState.newDate? props.parentState.newDate:props.course.fulldate}
                            giveDate={(date) => props.setParentState({newDate: date})}
                            giveEndDate={(d) => props.setParentStatee({regularEnd: d})}
                            giveDuration={(duration) => props.setParentState({newDuration: duration})}
                            toggle={() => props.setParentState({showPicker: !props.parentState.showPicker})}
                            submit={()=> props.fpatch(props.course._id)}
                            submitText={"Envoyer la modification"}
                        />
                    </React.Fragment>
                }
                <button className="btn yellow rounded" onClick={() => props.setParentState({showPicker: !props.parentState.showPicker})}>Modifier la date/heure/durée</button>
            </React.Fragment>}
            <button className="btn red rounded" onClick={()=> props.fcancel(props.course._id)}>Annuler le cours</button>
        </div>
    )
}
