import React, {useState} from 'react'
import axios from 'axios'


import OrderCard from '../Components/OrderCard'
import TeacherResume from './TeacherResume'


import { formatOrder } from '../functions/functions'

import IosArrowBack from 'react-ionicons/lib/IosArrowBack'

import { confirmAlert } from 'react-confirm-alert'


import "../css/teacher-carrousel.css"
import "../css/modal.css"


import config from '../config'
const api = config.api

/*props:
assignedOrders (list)
apiAddress (string)
auth (Object)
userData (Object)
setState (link to function)
*/

export default function TeacherCarrousel(props){
    const [teacherName, setTeacherName] = useState("")

    const reject = () => {
		const headers = {'Content-Type' : 'application/json',
			'key': 'hello',
			'id': props.auth.email,
			'authorization': props.auth.token
        }
		const teacher = props.teacher
		const data = { refused: teacher }
		axios.patch(`${api}/orders/${props.order._id}`, data, {headers: headers})
        props.updateCourses()
		//setTimeout(this.getCourses, 100, true)
		//this.setState({showConfirmModal: false})
	}

    const accept = () => {
		const headers = {'Content-Type' : 'application/json',
			'key': 'hello',
			'id': props.auth.email,
			'authorization': props.auth.token
		}
        /*
		const data = {teacher: this.state.currentCourse.acceptedBy[this.state.teacherIndex]}
		axios.patch(`${this.props.apiAddress}/v2/orders/${this.state.currentCourse._id}`, data, {headers: headers})
		setTimeout(this.getCourses, 100, true)
		this.setState({showConfirmModal: false})
        */

		const teacher = props.order.acceptedBy?.[props.index]
		const data = { teacher: teacher }
		axios.patch(`${api}/orders/${props.order._id}`, data,{headers: headers, data: data})
        props.updateCourses()
		//setTimeout(this.getCourses, 100, true)
		//this.setState({showConfirmModal: false})
	}

    const acceptOptions = {
      title: 'Confirmation',
      message: `Voulez vous accepter ${teacherName}?`,
      buttons: [
        {
          label: 'Accepter',
          onClick: accept
        },
        {
          label: 'Annuler',
          onClick: null
        }
      ],
      childrenElement: () => <div />,
      //customUI: ({ onClose }) => <div>Custom UI</div>,
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {console.log("a0")},
      afterClose: () => {console.log("a0")},
      onClickOutside: () => {console.log("a0")},
      onKeypressEscape: () => {console.log("a0")},
      overlayClassName: ""
    }

    const rejectOptions = {
      title: 'Confirmation',
      message: `Voulez vous refuser ${teacherName}?`,
      buttons: [
        {
          label: 'Refuser',
          onClick: reject
        },
        {
          label: 'Annuler',
          onClick: null
        }
      ],
      childrenElement: () => <div />,
      //customUI: ({ onClose }) => <div>Custom UI</div>,
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {console.log("a0")},
      afterClose: () => {console.log("a0")},
      onClickOutside: () => {console.log("a0")},
      onKeypressEscape: () => {console.log("a0")},
      overlayClassName: ""
    }

    return(
        <div className="teacher-carrousel box">
            <p> {props.order.acceptedBy.length > 1 ? `Ces ${props.order.acceptedBy.length} professeurs se sont proposés pour ce cours:`:"Un professeur s'est proposé pour ce cours:"} </p>
            <OrderCard
                key={props.index}
                data={formatOrder(props.order)}
                size="huge"
                apiAddress={props.apiAddress}
                auth={props.auth}
                userData={props.userData}
                    //setIndex(index-1)}}
            />
            <div className="carrousel">
                    <IosArrowBack
                        color={'#558'}
                        style={props.index === 0 ? btnStyles.backHidden:btnStyles.backVisible}
                        fontSize="4em"
                        //onClick={() => setIndex(props.index-1 > 0 ? props.index-1: 0)}
                        onClick={() => props.setState({carrouselIndex: props.index-1 > 0 ? props.index-1: 0})}
                    />

                    <TeacherResume
                        key={props.index}
                        teacher={(props.order.acceptedBy?.[props.index]) || props.order.teacher}
                        auth={props.auth}
                        setTeacher={setTeacherName}
                    />

                    <IosArrowBack
                        color={'#558'}
                        fontSize="4em"
                        style={props.index + 1 >= props.order.acceptedBy.length ? btnStyles.forwardHidden:btnStyles.forwardVisible}
                        //onClick={() => setIndex(props.index+1 === props.order.acceptedBy.length ? props.index: props.index+1)}
                        onClick={() => props.setState({carrouselIndex: props.index+1 === props.order.acceptedBy.length ? props.index: props.index+1})}
                    />
            </div>
            <div className="btn-group" style={accept_reject}>
                <div className="btn red half-btn left sm" onClick={() => confirmAlert(rejectOptions)}>
                    <p>{props.order.userData?.type==="pupil"? "Refuser ce professeur":"Refuser"}</p>
                </div>
                <div className="btn green half-btn right sm" onClick={() => confirmAlert(acceptOptions)}>
                    <p>{(props.userData?.type==="teacher" && !props.order.preferredTeacher)? "Se proposer":"Accepter"}</p>
                </div>
            </div>
            <p>{props.index+1+'/'+props.order.acceptedBy.length}</p>
        </div>
    )
}



const btnStyles = {
    backVisible: {cursor: 'pointer', visibility: 'visible'},
    backHidden: {cursor: 'pointer', visibility: 'hidden'},
    forwardVisible: {transform: 'rotate(180deg)', cursor: 'pointer', visibility: 'visible'},
    forwardHidden: {transform: 'rotate(180deg)', cursor: 'pointer', visibility: 'hidden'}
}

const accept_reject = {
    width: '50%'
}
