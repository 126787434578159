import React from 'react'
import axios from 'axios'
import {
    Link,
    Redirect
} from "react-router-dom"
import ReactGA from 'react-ga'

import ContactAndLegals from '../Components/ContactAndLegals'

import MdEye from 'react-ionicons/lib/MdEye'
import MdEyeOff from 'react-ionicons/lib/MdEyeOff'


import logo from '../files/images/logo.png'

import '../css/login.css'


export default class Register extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            firstname: "",
            lastname: "",
            email: "",
            number: "",
            pwd: "",
            confirm: "",
            emailSmall: "",
            userType: "",
            showPwd: false,
            showConfirm: false,
            displayReceiveEmail: false,
            isEmailOk: false,
            cguAgreed: false,
        }

        this.onChange = this.onChange.bind(this)
        this.checkFields = this.checkFields.bind(this)
        this.regIn = this.regIn.bind(this)

        ReactGA.pageview("Register");
    }


    onChange(event) {
        const name = event.target.name
        this.setState({
            [name]: event.target.value
        }, () => {
            if(name === "pwd" || name === "confirm"){
                this.checkPwdStrength();
                this.checkSamePwd()
            }
        })
    }

    checkEmail(event){
        event.preventDefault()
        event.stopPropagation()

        //This function checks the email before calling the registering function
        axios({url:this.props.apiAddress+'/v2/auth/validate-email/'+this.state.email,
        method: 'GET',
        key: 'hello',
        headers:{'Content-Type' : 'application/json', 'Access-Control-Allow-Origin' : this.props.apiAddress+'/auth/validate-email'}})
        .then(res => {
            this.setState({
                emailSmall:'',
                emailFrame: 'valid',
                isEmailOk: true,
            }, this.checkFields)

        })
        .catch((error)=> {
            console.log(error);
            if(error.response){
                if (error.response.status===409 || error.response.status===404){
                    this.setState({
                        emailSmall:'Adresse mail invalide ou déjà utilisée',
                        emailFrame: 'invalid',
                        isEmailOk: false,
                    })
                    /*ReactGA.event({category: 'Register',
                    action: 'Failed to register: invalid email'})*/
                }
            }
        });
    }

    checkFields(){
        let correctFields = 0

        if(this.state.firstname.length>0){
            correctFields++
            this.setState({firstnameFrame: 'valid',})
        }else{
            this.setState({firstnameFrame: 'invalid',})
        }

        if(this.state.lastname.length>0){
            correctFields++
            this.setState({lastnameFrame: 'valid',})
        }else{
            this.setState({lastnameFrame: 'invalid',})
        }

        if(this.state.email.length>0 && this.state.isEmailOk){
            this.setState({emailFrame: 'valid',})
        }else{
            this.setState({emailFrame: 'invalid',})
        }

        if(this.state.number.length === 10){
            correctFields++
            this.setState({numberFrame: 'valid',})
        }else{
            this.setState({numberFrame: 'invalid',})
        }

        if(this.state.userType !==""){
            correctFields++
            this.setState({userTypeFrame: 'valid',})
        }else{
            this.setState({userTypeFrame: 'invalid',})
        }

        this.checkPwdStrength()

        if(this.state.confirm !==""){
            correctFields++
            this.setState({confirmPwdFrame: 'valid',})
        }else{
            this.setState({confirmPwdFrame: 'invalid',})
        }
        console.log(correctFields)
        if(this.state.isConfirmOk && this.state.isPwdOk && this.state.isEmailOk && correctFields === 5){
            this.setState({displayReceiveEmail: true,})
            setTimeout(this.regIn, 15000)
        }


    }

    checkPwdStrength()	{
        let passwd = ""
        for(let i =0; i<2; i++){
            passwd = this.state.pwd
            if(passwd.length > 7){
                let strength = 0
                let hasMin = false
                let hasMaj = false
                let hasFigure = false
                let hasSpecial = false
                for(let i=0; i<passwd.length; i++){
                    if(passwd[i]>= 'a' && passwd[i] <= 'z' && !hasMin){
                        strength++
                        hasMin = true
                    }
                    if(passwd[i]>= '0' && passwd[i] <= '9' && !hasFigure){
                        strength++
                        hasFigure = true
                    }
                    if(passwd[i]>= 'A' && passwd[i] <= 'Z' && !hasMaj){
                        strength++
                        hasMaj = true
                    }
                    if((passwd[i]< '0' || passwd[i] > '9' || passwd[i] < 'A' || passwd[i] > 'Z' || passwd[i] < 'a' || passwd[i] > 'z') && !hasSpecial){
                        strength++
                        hasSpecial = true
                    }
                }
                if(strength===2){
                    this.setState({pwdStrength: 'Sécurité: bonne',
                    pwdFrame: 'valid',
                    isPwdOk: true})
                }else if(strength===3){
                    this.setState({pwdStrength: 'Sécurité: forte',
                    pwdFrame: 'valid',
                    isPwdOk: true})
                }else if(strength===4){
                    this.setState({pwdStrength: 'Sécurité: excellente',
                    pwdFrame: 'valid',
                    isPwdOk: true})
                }else{
                    this.setState({pwdStrength: 'Sécurité: moyenne' ,
                    pwdFrame: 'valid',
                    isPwdOk: true})
                }
            }else if(passwd.length > 7){
                this.setState({pwdStrength: 'Sécurité: moyenne' ,
                pwdFrame: 'valid',
                isPwdOk: true})
            }else if(passwd.length < 8){
                this.setState({pwdStrength: '8 caractères minimum',
                pwdFrame:'invalid',
                isPwdOk: false})
            }
        }
    }

    checkSamePwd(){
        if(this.state.confirm.length>0){
            if (this.state.pwd===this.state.confirm){
                this.setState({samePwd: 'Mots de passe identiques',
                confirmPwdFrame: 'valid',
                isConfirmOk: true})
            }
            else{
                this.setState({samePwd: 'Mots de passe différents',
                confirmPwdFrame: 'invalid',
                isConfirmOk: false})
            }
        }
    }

    regIn(){
        const headers = {'Content-Type' : 'application/json',
        'key': 'hello',
        'Access-Control-Allow-Origin' : this.props.apiAddress+'/'}

        const data = {  email: this.state.email,
            password: this.state.pwd,
            confirmPassword: this.state.confirm,
            user_type: this.state.userType,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            promo: this.state.promo,
            number: this.state.number}

            axios.post(this.props.apiAddress+'/v2/auth/signup',
            data,
            {headers: headers})
            .then((res) =>{
                this.setState({redirect: "/login"})
            })
            .catch((err) => console.log(err))
        }

        render(){
            return(
                <div className="container home-container">
                    <ContactAndLegals />
                    {this.state.redirect? <Redirect to={this.state.redirect} />: null}
                    <img src={logo} id="register-logo" alt="logo" />
                    {this.state.displayReceiveEmail? <p id="register-warning" style={{fontSize: '1.5em', color: 'black'}}>Vous allez être redirigé(e) vers la page de connexion.<br/> Avant de vous connecter, veuillez <strong>confirmer votre adresse mail</strong> via l'email que nous vous avons envoyé.</p>:null}
                    <h3>Inscription</h3>
                    <p id='subtitle'>Après vous être inscrit, vous pourrez commander votre premier cours !</p>
                    <form onSubmit={(e) => this.checkEmail(e)}>
                        <div className="btn-group">
                            <button type='button'
                                onClick={() => this.setState({userType: "pupil"})}
                                className={this.state.userType==="pupil"?
                                "btn form-btn half-btn left selected "+this.state.userTypeFrame
                                :
                                "btn form-btn half-btn left "+this.state.userTypeFrame}>
                                Élève
                            </button>
                            <button type='button'
                                onClick={() => this.setState({userType: "teacher"})}
                                className={this.state.userType==="teacher"?
                                "btn form-btn half-btn right selected "+this.state.userTypeFrame
                                :
                                "btn form-btn half-btn right "+this.state.userTypeFrame}>
                                Professeur
                            </button>
                        </div>
                        <input 	value={this.state.firstname}
                            onChange={this.onChange}
                            name='firstname'
                            type="text"
                            className={"form-control "+this.state.firstnameFrame+" home-form"}
                            placeholder="Prénom"
                        />
                        <input 	value={this.state.lastname}
                            onChange={this.onChange}
                            name='lastname'
                            type="text"
                            className={"form-control "+this.state.lastnameFrame+" home-form"}
                            placeholder="Nom"
                        />
                        <input 	value={this.state.number}
                            onChange={this.onChange}
                            name='number'
                            type="text"
                            className={"form-control "+this.state.numberFrame+" home-form"}
                            placeholder="Téléphone"
                        />
                        <input 	value={this.state.email}
                            onChange={this.onChange}
                            name='email'
                            type="email"
                            className={"form-control "+this.state.emailFrame+" home-form"}
                            placeholder="Email"
                        />
                        <small style={{color: 'red'}}>{this.state.emailSmall}</small>
                        <input 	value={this.state.pwd}
                            onChange={this.onChange}
                            name='pwd'
                            type={this.state.showPwd? "text":"password"}
                            className={"form-control "+this.state.pwdFrame+" home-form"}
                            placeholder="Mot de Passe"
                        />
                        <div type="button" className='showPwd'>
                            {this.state.showPwd?
                                <MdEye
                                    onClick={() => this.setState({showPwd: !this.state.showPwd})}
                                    fontSize="25px"
                                    style={{cursor: "pointer"}}
                                    color="#333"
                                />
                                :
                                <MdEyeOff
                                    onClick={() => this.setState({showPwd: !this.state.showPwd})}
                                    fontSize="25px"
                                    style={{cursor: "pointer"}}
                                    color="#333"
                                />
                            }
                        </div>
                        <small style={this.state.isPwdOk? {color: 'green'}:{color: 'red'}}>{this.state.pwdStrength}</small>

                        <input 	value={this.state.confirm}
                            onChange={this.onChange}
                            name='confirm'
                            type={this.state.showPwd? "text":"password"}
                            className={"form-control "+this.state.confirmPwdFrame+" home-form"}
                            placeholder="Confirmation"
                        />
                        <div type="button" className='showPwd'>
                            {this.state.showPwd?
                                <MdEye
                                    onClick={() => this.setState({showPwd: !this.state.showPwd})}
                                    fontSize="25px"
                                    style={{cursor: "pointer"}}
                                    color="#333"
                                />
                                :
                                <MdEyeOff
                                    onClick={() => this.setState({showPwd: !this.state.showPwd})}
                                    fontSize="25px"
                                    style={{cursor: "pointer"}}
                                    color="#333"
                                />
                            }
                        </div>
                        <small style={this.state.isConfirmOk? {color: 'green'}:{color: 'red'}}>{this.state.samePwd}</small>

                        <input 	value={this.state.promo}
                            onChange={this.onChange}
                            name='promo'
                            type="text"
                            className={"form-control home-form"}
                            placeholder="Parrainage"
                        />
                        <div style={{textAlign: 'left'}}>
                            <input type="checkbox" id="scales" name="scales"
                                onClick={() => this.setState({cguAgreed: !this.state.cguAgreed})}
                                checked={this.state.cguAgreed}
                            />
                            <label for="scales">J'accepte les conditions générales d'utilisation:
                                <a target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://api.expliquemoipythagore.fr/v2/public/cgu"
                                    className="btn link blue">CGU
                                </a>
                            </label>
                            {this.state.userType === "pupil" &&
                                <>
                                    <br/>
                                    <input type="checkbox" id="scales" name="scales"
                                        onClick={() => this.setState({contractAgreed: !this.state.contractAgreed})}
                                        checked={this.state.contractAgreed}
                                    />
                                    <label for="scales">J'accepte les termes de ce&nbsp;
                                        <Link style={{margin: 0, padding: 0}} className="btn link blue" to="/contrat" target="_blank" rel="noopener noreferrer">contrat</Link>.

                                    </label>
                                </>
                            }
                        </div>
                        <button
                            type='submit'
                            id='homeButton'
                            disabled={!( (this.state.cguAgreed && this.state.userType === "teacher") || ( (this.state.cguAgreed && this.state.contractAgreed) && this.state.userType === "pupil") )}
                            className={( (this.state.cguAgreed && this.state.userType === "teacher") || ( (this.state.cguAgreed && this.state.contractAgreed) && this.state.userType === "pupil") )? 'btn form-btn':'btn form-btn disabled'} >
                            S'inscrire
                        </button>
                        <br/><br/>
                        <Link to="/login" className="btn green no-decoration lg">Déjà inscrit ? Se connecter</Link>
                    </form>
                </div>
            )
        }
    }
