import React from 'react'

import IosCallOutline from 'react-ionicons/lib/IosCallOutline'
import IosMailOutline from 'react-ionicons/lib/IosMailOutline'
import MdHomeOutline from 'react-ionicons/lib/IosHomeOutline'
import IosPaperOutline from 'react-ionicons/lib/IosPaperOutline'
import LogoEuro from 'react-ionicons/lib/IosPricetagsOutline'
import MdPaper from 'react-ionicons/lib/MdPaper'

import '../css/contact-and-legals.css'

export default class ContactAndLegals extends React.Component{
    constructor(){
        super()
        this.state = {
            showPwd: false,
            showForgotPassword: false,
            showAdForApp: true,
            showLegals: false
        }
    }
    render() {
        let mainstyle = this.props.horizontalLayout ? "contact-and-legals row start ":"contact-and-legals column start "
        mainstyle += this.props.relativePosition? " hey":"contact"

        let numberstyle = this.props.horizontalLayout ? "number horizontal":"number vertical"

        const color = this.props.white? '#fff':'#557'
        return(
            <div className={mainstyle}>
                {!this.props.noHome && <a className="btn link white column" href="https://expliquemoipythagore.fr/" target="_blank" rel="noopener noreferrer">
                <MdHomeOutline
                    fontSize="2em"
                    color={color}/>
                    {!this.props.noLabel && <p style={{fontSize: '0.5em', margin: '0px', color: color}}>Accueil</p>}
                </a>}
                <a
                    className="btn link white"
                    href='mailto:contact@expliquemoipythagore.fr'
                    rel="noopener noreferrer">
                    <IosMailOutline
                        fontSize="2em"
                        color={color}
                    />
                    {!this.props.noLabel && <p style={{fontSize: '0.55em', margin: '0px', color: color, marginTop: '-5px'}}>Mail</p>}

                </a>
                <div
                    className="btn link white number-frame">
                    <IosCallOutline
                        fontSize="2em"
                        color={color}
                    />
                    <p className={numberstyle}>06 44 76 91 78</p>
                    {!this.props.noLabel && <p style={{fontSize: '0.55em', margin: '0px', color: color, marginTop: '-5px'}}>Tel.</p>}

                </div>
                <a className="btn link white column" href="https://expliquemoipythagore.fr/#tarifs" target="_blank" rel="noopener noreferrer">
                <LogoEuro
                    fontSize="2em"
                    color={color}/>
                    {!this.props.noLabel && <p style={{fontSize: '0.5em', margin: '0px', color: color}}>Nos Tarifs</p>}
                </a>
                {/*
                    <a className="btn link white column" href="https://blog.expliquemoipythagore.fr/nos-prix/">
                    <MdContacts
                    fontSize="2em"
                    color={color}/>
                    <p style={{fontSize: '0.5em', margin: '0px', color: color}}>Nos Partenaires</p>
                </a>
                */}
                {!this.props.noLegals &&
                    <button
                        onClick={() => this.setState({showLegals: !this.state.showLegals})}
                        className="btn link white">
                        {this.state.showLegals?
                            <div className="legals column bg-white rounded">
                                <div className="row">
                                    <a href="https://api.expliquemoipythagore.fr/v2/public/rgpd"
                                    target="_blank" rel="noopener noreferrer" className="btn link blue">RGPD</a>
                                    <a target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://api.expliquemoipythagore.fr/v2/public/cgu"
                                        className="btn link blue">CGU</a>
                                    </div>
                                    <a target="_blank" rel="noopener noreferrer"
                                        href="https://api.expliquemoipythagore.fr/v2/public/protection-donnees"
                                        className="btn link blue">Protection <br/> des données</a>
                                    </div>
                                    :
                                    <React.Fragment>
                                        <MdPaper
                                            fontSize="2.8em"
                                            color={color}/>
                                            <p style={{fontSize: '0.5em', margin: '0px', color: color}}>Mentions <br/>légales</p>
                                        </React.Fragment>
                                    }
                                </button>
                            }
                        </div>
                    )
                }
            }
