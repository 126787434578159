import React from 'react'
/*import axios from 'axios'
import IosFlame from 'react-ionicons/lib/IosFlame'*/
import IosRepeat from 'react-ionicons/lib/IosRepeat'
import '../css/ordercard.css'

import {durationToString } from '../functions/functions'
import TeacherResume from './TeacherResume'

export default function OrderCard(props){

	//const [teacherData, setTeacherData] = useState({})

	/*useEffect(() => {
	const headers = {
	'Content-Type' : 'application/json',
	'key': 'hello',
	'Access-Control-Allow-Origin' : props.apiAddress+'/profile',
	'id': props.teacher,
	'authorization': props.auth.token
}
axios.get(props.apiAddress+'/v2/profile',
{headers: headers})
.then((doc) => {
setTeacherData(doc.data)
})
}, [])*/

const picstyle= {color: 'blue'}//{position: 'relative', top: '0px', right: '0px', alignSelf: 'flex-end', margin: '0px', padding: '0px', marginBottom: '-20px'}
return(
	<div className={"ordercard container "+props.size+" "+props.data.color+" "+(props.active?"active ":"")+(props.confirmable?"":"no-buttons ")}>
		<>
		<div
			className="ordercard head row space-between full-width">
			<p className="ordercard status">{props.data.statusName}</p>
			{ props.userData.type === "teacher"?
			<p className="ordercard name">{props.data.pupil}</p>
			:
			<p className="ordercard name">{props.data.teacherName}</p>
		}
		{/*}{!props.data.regular && */}
			<div style={picstyle}><IosRepeat
				fontSize="1.2em"
				color="#0d4ed9"/>
			</div>
			
		</div>
		<div className="ordercard body">
			<div className="ordercard left">
				<p className="ordercard date">{props.data.date}</p>
				<p className="ordercard level">{props.data.level.name}</p>
				<p className="ordercard price">{props.data.duration ? (props.data.remote ? props.data.level.remotePrice : props.data.level.price) * props.data.duration : props.data.level.price}€</p>
			</div>
			<div className="ordercard right">
				<p className="ordercard time">{props.data.time.hour}h{props.data.time.minute}&nbsp;|&nbsp;{durationToString(props.data.duration)}</p>
				<p className="ordercard topic">{props.data.topic.name}</p>
				{props.data.remote?
					<p>
						Visio
					</p>
					:
					<React.Fragment>
						{props.data.address && <p className="ordercard address1">{props.data.address.number}&nbsp;{props.data.address.street}</p>}
						{props.data.address && <p className="ordercard address2">{props.data.address.zipcode}&nbsp;{props.data.address.city}</p>}
					</React.Fragment>
				}
			</div>
		</div>
	</>
	{props.teacherResume &&
		<TeacherResume
			teacher={(props.data.acceptedBy?.[props.teacherIndex]) || props.data.teacher}
			auth={props.auth}
			apiAddress={props.apiAddress}
		/>
	}
	{props.data.precisions && props.confirmable && props.userData.type === "teacher" &&
	<p className="scroll-y" style={{maxWidth:"20vw"}} >Commentaire: "{props.data.precisions}"</p>
}
{props.confirmable?
	<div className="btn-group full-width">
		<div className="btn red sm square" onClick={() => props.selectCourseAndChoose(false)}>
			<p>{props.data.userData?.type==="pupil"? "Refuser ce professeur":"Refuser"}</p>
		</div>
		<div className="btn green sm square" onClick={() => props.selectCourseAndChoose(true)}>
			<p>{(props.userData?.type==="teacher" && !props.data.preferredTeacher)? "Se proposer":"Accepter"}</p>
		</div>
	</div>
	:
	null
}
</div>
)

}
