import React from 'react'

import '../css/addaddress.css'

import { renderOptions } from '../functions/functions'

export default class AddProfile extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            name: "",
            level: null,
        }

        this.onChange = this.onChange.bind(this)
    }

    onChange(event) {
        const name = event.target.name
        this.setState({
            [name]: event.target.value
            })
    }

    getSelect(element){
        const index = element.target.value
        if(index !== 1000){
            this.setState({level: this.props.utility.levels[index]})
        }else{
            this.setState({level: null})
        }
    }

    render(){
        return(
               <div className="addaddress">
                   <p id="title">Ajouter un profil</p>
                   <div className="row">
                       <input
                           value={this.state.name}
                           onChange={this.onChange}
                           name='name'
                           type="name"
                           className="form-control"
                           id="name"
                           placeholder="Nom"/>
                       <select className="btn blue link orderer-select" id="level" onChange={(e) => this.getSelect(e)}>
                           <option key={1000} value={1000}>
                               Niveau
                           </option>
                           {renderOptions(this.props.utility.levels)}
                         </select>
                   </div>
                   <div className="row full-width">
                       <button
                           className='btn link sm green'
                           onClick = {()=>this.props.addProfile(this.state)}
                           >
                               {(this.state.level !== null && this.state.level !== undefined && this.state.name !== "")? this.props.properlyRegistered ? "Enregistré !":"Enregistrer":<></>}
                       </button>
                   </div>
               </div>
        )
    }


}
