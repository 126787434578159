import React from 'react'

import '../css/addaddress.css'

export default class AddAddress extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			number: "",
			street: "",
			precision: "",
			city: "",
			zipcode: "",
			valid: false,
		}

		this.onChange = this.onChange.bind(this)
	}

	onChange(event) {
		const name = event.target.name
		this.setState({
			[name]: event.target.value,
		}, () => this.setState({valid: this.check()}))
	}

	check(){
		for (let p in this.state){
			if(p !== "precision"){
				if(this.state[p] === ""){
					return false
				}
			}
		}
		return true
	}

	submit(){
		this.props.addAddress(this.state)
	}

	render(){
		return(
			<div className="addaddress">
				<p id="title">Ajouter une adresse</p>
				<div className="row">
					<input
						value={this.state.number}
					    onChange={this.onChange}
					    name='number'
					    type="name"
					    className="form-control"
					    id="number"
					    placeholder="Numéro"/>
					<input
						value={this.state.street}
					    onChange={this.onChange}
					    name='street'
					    type="name"
					    className="form-control"
					    id="street"
					    placeholder="Rue"/>
				</div>
				<div className="row full-width">
					<input 	value={this.state.precision}
					    onChange={this.onChange}
					    name='precision'
					    type="name"
					    className="form-control"
					    id="precision"
					    placeholder="(opt.) bâtiment, appartement, digicode"/>
				</div>
				<div className="row full-width">
				  	<input 	value={this.state.zipcode}
					    onChange={this.onChange}
					    name='zipcode'
					    type="name"
					    className="form-control"
					    id="zipcode"
					    placeholder="C. postal"/>
				  	<input 	value={this.state.city}
					    onChange={this.onChange}
					    name='city'
					    type="name"
					    className="form-control"
					    id="city"
					    placeholder="Ville"/>
				</div>
				<div className="row full-width">
					<button
						type="button"
						className={this.state.valid? 'btn link sm green':'btn link sm green disabled'}
						onClick = {()=>this.submit()} //this.props.addAddress(this.state)
						>
							{this.props.properlyRegistered? "Enregistré !":"Enregistrer"}
					</button>
				</div>
			</div>
		)
	}
}
