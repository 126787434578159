import React from "react"
import axios from 'axios'
import {
    Link
} from "react-router-dom"
import ReactGA from 'react-ga'
import ImageUploader from 'react-images-upload'

import {
    Redirect
} from "react-router-dom"

import AddAddress from '../Components/AddAddress'
import AddProfile from '../Components/AddProfile'
import Subscription from '../Components/Subscription'
import CityPicker from '../Components/CityPicker'

import { renderAddresses, renderProfiles, formatDate, formatTelNumber } from '../functions/functions'

import '../css/biguserdata.css'
import '../css/forms.css'

import defaultAvatar from '../files/images/Missing_avatar.png'

import tuto_visio_site from '../files/tutos/Tuto_acceder_a_la_visio_par_le_site.pdf'
import tuto_bbb from '../files/tutos/Tuto_acceder_a_la_visio_BBB_prof.pdf'
import tuto_bbb_eleve from '../files/tutos/Tuto_acceder_a_la visio_BBB_parent.pdf'


export default class BigUserData extends React.Component{
    constructor	(props){
        super(props)
        this.state = {
            firstname: this.props.userData.firstname,
            lastname: this.props.userData.lastname,
            number: this.props.userData.number,
            addressToChange: {},
            showLevelsOf: 0,
            addPicture: false,
        }

        this.onChange = this.onChange.bind(this)
        this.updateData = this.updateData.bind(this)
        this.recordLevel = this.recordLevel.bind(this)
        this.recordNamesAndNumber = this.recordNamesAndNumber.bind(this)
        this.deleteAddress = this.deleteAddress.bind(this)
        this.addAddress = this.addAddress.bind(this)
        this.addProfile = this.addProfile.bind(this)
        this.deleteProfile = this.deleteProfile.bind(this)
        ReactGA.pageview("userdata("+this.props.userData.type+")")
        this.onDrop = this.onDrop.bind(this);
    }

    componentDidMount = () => {
        //redirect to passes: used for pass status CTA
        const words = window.location.href.split('/')
        if(words[words.length - 1] == "passes") this.setState({show_subs: true})
    }

    onDrop(pictureFiles, pictureDataURLs) {
        this.setState({
            pictures: {files: pictureFiles, dataURLs: pictureDataURLs}
        });
    }

    sendPicture(){
        const headers={
            'Content-Type' : 'application/json',
            'key': 'hello',
            'id': this.props.auth.email,
            'Accept': 'application/json',
            'token': this.props.auth.token,
        }
        const img = this.state.pictures.dataURLs[0].split(',')[1]//this.state.pictures.substring(this.state.p.indexOf(",") + 1)
        const data = {picture: img}
        axios.post(this.props.apiAddress+'/updatePicture', data, {headers: headers})
        .then(
            (res) => {
                console.log(res)
                this.setState({addPicture: false})
                this.updateData()
            })
            .catch((err)=>{console.log(err)})
        }


        onChange(event) {
            const name = event.target.name
            this.setState({
                [name]: event.target.value
            })
        }


        recordNamesAndNumber(){
            const headers={'Content-Type' : 'application/json',
            'key': 'hello',
            'id': this.props.auth.email,
            'Accept': 'application/json'}

            const data = {
                lastname: this.state.lastname,
                firstname: this.state.firstname,
                number: this.state.number
            }
            if(this.state.number.length < 10 || this.state.number.length > 10 || this.state.firstname.length === 0 || this.state.lastname.length === 0){
                return null
            }
            axios.post(this.props.apiAddress+'/profile', data, {headers: headers})
            .then(() => {this.updateData()})
            .catch((err)=>{console.log(err)})
        }

        updateData(){
            this.props.updateData()
        }

        recordLevel(event){
            const index = event.target.value
            console.log(index)
            console.log(this.props.utility.levels[index]._id)
            if(index === '1000'){//happens when it's the predefined topic/level that is choosen
            return null
        }
        const headers={
            'Content-Type' : 'application/json',
            'key': 'hello',
            'id': this.props.auth.email,
            'Accept': 'application/json',
            'token': this.props.auth.token
        }

        const data = {_id: this.props.utility.levels[index]._id}
        axios.post(this.props.apiAddress+'/setLevel', data, {headers: headers})
        .then( () => {
            this.setState({alertLevel: <small style={{color: '#23d4ac'}}>Enregistré !</small>})
            this.updateData()
        })
        .catch((err)=>console.log(err))
    }

    addAddress(address){
        const headers = {
            'Content-Type': 'application/json',
            'key': 'hello',
            'id': this.props.auth.email,
            'token': this.props.auth.token
        }
        const data = address
        axios.post(this.props.apiAddress+'/addAddress', data, {headers: headers})
        .then(async (res) =>{
            this.setState({addressAdded: true})
            //this.setTimeout(() => this.setState({addressAdded: false}), 5000)
            this.props.updateData("profile")
            ReactGA.event({
                category: 'Address',
                action: 'Added'
            })
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    addProfile(profile){
        const headers = {
            'Content-Type': 'application/json',
            'key': 'hello',
            'id': this.props.auth.email,
            'authorization': this.props.auth.token
        }

        const data = profile
        axios.post(this.props.apiAddress+'/v2/profiles', data, {headers: headers})
        .then((res) =>{
            this.setState({profileAdded: true})
            this.props.updateProfiles()
            ReactGA.event({
                category: 'Profile',
                action: 'Added'
            })
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    deleteAddress(address){
        const headers = {
            'Content-Type': 'application/json',
            'key': 'hello',
            'id': this.props.auth.email,
            'token': this.props.auth.token,
            'Accept': 'application/json',
        }

        const data = address
        axios.post(this.props.apiAddress+'/deleteAddress', data, {headers: headers})
        .then((res)=> {
            this.props.updateData("profile")
            ReactGA.event({
                category: 'Address',
                action: 'Deleted'
            })
        })
        .catch(function (error) {

            console.log(error);
        });
    }

    deleteProfile(profile){
        const headers = {
            'Content-Type': 'application/json',
            'key': 'hello',
            'id': this.props.auth.email,
            'authorization': this.props.auth.token
        }

        const data = profile
        axios.delete(this.props.apiAddress+'/v2/profiles', { data: data, headers: headers })
        .then((res)=> {
            this.props.updateProfiles()
            ReactGA.event({
                category: 'Profile',
                action: 'Deleted'
            })
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    renderTopics(topics){
        return topics.map((item, i) => <p className={this.state.showLevelsOf === i? "topic-item active":"topic-item"} onClick={() => this.setState({showLevelsOf: i})} key={i}>{item.name}</p>)
    }

    renderLevelsOf(topics, index){
        if(topics[0]){
            return topics[index].levels.map((item, i) => <p onClick={() => this.setState({showLevelsOf: i})} key={i}>{item}</p>)
        }else{
            return <p></p>
        }
    }

    closeSubs = () => {
        this.setState({show_subs: false})
        console.log(window.location.href.substring(0, window.location.href.length - 6))
        return <Redirect to={window.location.href.substring(window.location.href.length - 6)} />
    }

    render(){
        return(
            <div className="biguserdata expanded center row scroll-y">

                {this.state.show_subs?
                    <Subscription
                        auth={this.props.auth}
                        apiAddress={this.props.apiAddress}
                        userData={this.props.userData}
                        addAddress={(a) => this.addAddress(a)}
                        comeBack={() => this.closeSubs()}
                    />
                :
                <React.Fragment>
                    <div className="column first center">
                        {(this.state.addPicture && this.props.userData.type==="teacher")?
                            <div className="column">
                                <ImageUploader
                                    withIcon={this.state.usertrue}
                                    buttonText='Choisir une photo '
                                    onChange={this.onDrop}
                                    imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                    maxFileSize={5242880}
                                    singleImage={true}
                                    withLabel={false}
                                    fileContainerStyle={{boxShadow: 'none'}}
                                />
                                <p>{
                                    this.state.pictures &&
                                    this.state.pictures.dataURLs[0]?.split('name=')[1]?.split(';')[0]}</p>
                                <div className="row">
                                    <button
                                        className="btn yellow lg"
                                        style={{marginTop: "-0.25em"}}
                                        onClick={() => this.setState({addPicture: false})}>
                                        Annuler
                                    </button>
                                    <button
                                        className="btn green lg"
                                        style={{marginTop: "-0.25em"}}
                                        onClick={() => this.sendPicture()}>
                                        Valider
                                    </button>
                                </div>
                            </div>
                        :
                        <>
                            {this.props.userData._id}
                            <hr className="full-width"/>
                            <img
                                alt=""
                                className="avatar"
                                onClick={() => this.setState({addPicture: true})}
                                src={this.props.userData.avatar? this.props.userData.avatar:defaultAvatar}
                            />
                        </>
                        }

                        <div id='name-and-number' className="column">
                            <p>{this.state.firstname} {this.state.lastname}</p>
                            <p>{formatTelNumber(this.state.number)}</p>
                            {/*}<input
                                className="text-input oneline"
                                type="text" value={this.state.firstname}
                                placeholder={this.props.userData.firstname}
                                name="firstname"
                                onChange={this.onChange}
                                />
                                <input
                                className="text-input oneline"
                                type="text"
                                value={this.state.lastname}
                                placeholder={this.props.userData.lastname}
                                name="lastname"
                                onChange={this.onChange}
                                />

                                <input
                                className="text-input oneline"
                                type="text"
                                value={this.state.number}
                                placeholder={this.props.userData.number}
                                name="number"
                                minLength="10"
                                maxLength="10"
                                onChange={this.onChange}
                            />*/}
                        </div>
                        {this.props.userData.type==="teacher" &&
                            <div className="column center">
                                <div className="container full-width">
                                    Mes accréditations
                                    <hr/>
                                    <div id="accreditations" className="row">
                                        <div className="topics">
                                            {this.props.userData.topics?.[0]? this.renderTopics(this.props.userData.topics): <p>Nous n'avons pas encore renseigné vos matières</p>}
                                        </div>
                                        <div className="levels">
                                            {this.props.userData.topics?.[0]? this.renderLevelsOf(this.props.userData.topics, this.state.showLevelsOf):null}
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                    <div className="column secondary center">
                        {
                            this.props.userData.type==="pupil"?
                                <div className="adresses container full-width">
                                    Mes adresses
                                    <hr className="full-width"/>
                                    <div className="addresses scroll-y">
                                        {this.props.userData.addresses&&
                                            this.props.userData.addresses.length > 0 ?
                                                renderAddresses(
                                                    this.props.userData.addresses,
                                                    "wrapped",
                                                    2,
                                                    [(a) => this.setState({addressToChange: a}), (a) => this.deleteAddress(a)],
                                                    ["modifier", "supprimer"]
                                                )
                                            :
                                            <p style={{textAlign:'center'}}>Ajoutez une adresse pour pouvoir commander un cours à domicile!</p>}
                                    </div>
                                    <AddAddress
                                        address={this.state.addressToChange}
                                        addAddress={(address) => this.addAddress(address)}
                                        properlyRegistered={this.state.addressAdded}
                                    />
                                </div>
                            ://the user is a teacher
                            <React.Fragment>
                                <div id="resume-container" className="column center">
                                    {/*<CityPicker />*/}
                                    <div className="container full-width">
                                        Mon CV
                                        <hr/>
                                        <div id="infos" className="column" style={{margin: '1px'}}>
                                            {this.props.userData.infos ? Object.keys(this.props.userData?.infos).map((key, i) => (
                                                <div key={i} style={{ marginBottom: '2px', textAlign: 'left', alignItem: 'left', width: '100%'}}>
                                                    <p className="resume-label" style={{ fontWeight: 'bold', margin: '1px',}}>{this.props.userData?.infos[key] && key}</p>
                                                    <p style={{ margin: '1px' }}>{this.props.userData?.infos[key]}</p>
                                                </div>
                                            ))
                                            :
                                            <div>
                                                <p> Si vous n'avez pas encore complété votre CV, veuillez le faire en remplissant le formulaire ci-dessous:</p>
                                                <a href="https://forms.gle/tQ2TpWBbVUZyKKiKA" target="_blank" rel="noopener noreferer">Renseigner mon CV</a>
                                                <br/>
                                                <br/>
                                                <p>
                                                    Dans le cas contraire, votre CV est en cours de traitement et vos informations devraient être bientôt mises à jour.
                                                </p>
                                                {/*
                                                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSevN5VLLIAjw2pgvA1IaRewm_uFPxzGOXiPKgtz_OwX95uhyQ/viewform?embedded=true"
                                                    align="center"
                                                    frameBorder="0"
                                                    >
                                                    <a id="gglFormLink" href="https://docs.google.com/forms/d/e/1FAIpQLSevN5VLLIAjw2pgvA1IaRewm_uFPxzGOXiPKgtz_OwX95uhyQ/viewform" target="_blank">
                                                    Compléter mes informations
                                                    </a>
                                                    </iframe>
                                                */}
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>}
                    </div>
                    {this.props.userData.type==="pupil"&&
                        <div className="column fourth    flex-start">
                            <div className="column  center">
                                <div className="container full-width">
                                    Mes profils
                                    <hr className="full-width"/>
                                    {this.props.profiles &&
                                        this.props.profiles.length > 0 &&
                                        <div className="profiles full-width">
                                            {renderProfiles(
                                                this.props.profiles,
                                                "oneline",
                                                2,
                                                [(p) => this.setState({profileToChange: p}), (p) => this.deleteProfile(p)],
                                                ["modifier", "supprimer"]
                                            )}
                                        </div>
                                    }
                                    <AddProfile
                                        address={this.state.addressToChange}
                                        addProfile={(profile) => this.addProfile(profile)}
                                        properlyRegistered={this.state.profileAdded}
                                        utility={this.props.utility}
                                    />
                                </div>
                            </div>
                            <div className="column  center">
                                <div className="container full-width">
                                    Tutoriels
                                    <hr className="full-width"/>
                                    <a href={tuto_bbb_eleve} target="_blank" rel="noopener noreferrer" className="btn link blue">Utiliser BBB pour les cours en visio</a>
                                </div>
                            </div>
                        </div>}
                    <div className="column fourth flex-start">
                        {this.props.userData.type==="pupil" &&
                            <div className="column  center">
                                <div className="container full-width">
                                    Mon abonnement
                                    <hr className="full-width"/>
                                    <div style={{textAlign: 'center'}}>
                                        <p>
                                            {this.props.userData.subscription?.status === 'active' ?
                                                'Abonnement actif'
                                            : (this.props.userData.freeOrder?
                                                "Vous bénéficiez d'un cours d'essai !"
                                            :
                                            "Vous n'êtes pas abonné")
                                            }
                                        </p>
                                        <br/>
                                        <br/>
                                        <button className="btn green lg"
                                            style={{marginTop: "-0.25em"}}
                                            onClick={() => {this.setState({show_subs: true})}}
                                        >
                                            {this.props.userData.subscription ? 'Gérer mon abonnement' : 'M\'abonner'}
                                        </button>
                                    </div>
                                </div>
                            </div>}
                        {this.props.userData.type==="pupil"?
                            <div className="column center">
                                <div className="container full-width">
                                    Documents
                                    <hr className="full-width"/>
                                    <Link className="btn link blue" to="/contrat" target="_blank" rel="noopener noreferrer">Voir le contrat</Link>
                                    <a href="https://api.expliquemoipythagore.fr/v2/public/rgpd" target="_blank" rel="noopener noreferrer" className="btn link blue">RGPD</a>
                                    <a target="_blank"
                                        rel="noopener noreferrer"
                                    href="https://api.expliquemoipythagore.fr/v2/public/cgu" className="btn link blue">CGU
                                    </a>
                                    <a target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://api.expliquemoipythagore.fr/v2/public/protection-donnees"
                                    className="btn link blue">Politique de protection des données
                                    </a>
                                </div>
                            </div>
                        :
                        <React.Fragment>
                            <div className="column center">

                                <div className="container full-width">
                                    Tutoriels
                                    <hr className="full-width"/>
                                    <a href={tuto_bbb} target="_blank" rel="noopener noreferrer" className="btn link blue">Accéder à la visio</a>
                                    <a href={tuto_bbb_eleve} target="_blank" rel="noopener noreferrer" className="btn link blue">Utiliser BBB pour les cours en visio</a>
                                </div>
                            </div>
                            <div className="column center">

                                <div className="container full-width">
                                    Documents
                                    <hr className="full-width"/>
                                    <a rel="noopener noreferrer"
                                    href="https://api.expliquemoipythagore.fr/v2/public/rgpd" target="_blank" className="btn link blue">RGPD
                                    </a>
                                    <a target="_blank"
                                        rel="noopener noreferrer"
                                    href="https://api.expliquemoipythagore.fr/v2/public/cgu" className="btn link blue">CGU
                                    </a>
                                    <a target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://api.expliquemoipythagore.fr/v2/public/protection-donnees"
                                    className="btn link blue">Politique de protection des données
                                    </a>
                                </div>
                            </div>
                        </React.Fragment>}
                    </div>
                </React.Fragment>
                }
            </div>
)
}
}
