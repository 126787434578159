import React,  { useState } from 'react'

import { Redirect } from "react-router-dom"

//import { useHistory } from 'react-router';

import { formatAddress, formatDate, deOrD, durationToString } from '../functions/functions'

import '../css/order-resume.css'


function OrderResume(props){

	const [prec, setPrec] = useState("")
	const [redirect, setRedirect] = useState("")
/*
		const history = useHistory();
		const [prec, setPrec] = useState("")
*/		return (
		<div className="order-resume">
			{redirect && <Redirect to={redirect} />}
			<h1 id="order-resume-head">Résumé</h1>

			<h2 id="order-resume-warning">Merci de vérifier les informations de votre cours</h2>

			<div id="order-resume-body">
				<div className="column first">
					<div className="order-resume-line"><p>Cours {deOrD(props.order.order.topic.name)}</p><p className="order-resume-item">{props.order.order.topic.name}</p></div>
					<div className="order-resume-line"><p>en&nbsp;</p><p className="order-resume-item">{props.order.order.level.name}</p></div>
					<div className="order-resume-line"><p>{props.order.order.remote? "en":"à"}&nbsp;</p><p className="order-resume-item">&nbsp;{props.order.order.remote? 'Visio':formatAddress(props.order.order.address, "oneline")}</p></div>
					<div className="order-resume-line"><p>le&nbsp;</p><p className="order-resume-item">{formatDate(props.order.order.date, "object").date}</p></div>
					<div className="order-resume-line"><p>à&nbsp;</p><p className="order-resume-item">{formatDate(props.order.order.date, "object").hour}</p></div>
				</div>
				<div className="column secondary">
					<div className="order-resume-line"><p>Durée&nbsp;</p><p className="order-resume-item">{durationToString(props.order.order.duration)}</p></div>
					<div className="order-resume-line"><p>Professeur:&nbsp;</p><p className="order-resume-item">{props.teacher !==null ? props.teacher: "Aucun"}</p></div>
					<div className="order-resume-line"><p>Prix:&nbsp;</p><p className="order-resume-item">{props.order.order.remote? props.order.order.level.remotePrice*props.order.order.duration:props.order.order.level.price*props.order.order.duration}</p><p>€</p></div>
					<div className="order-resume-line"><p>Cours&nbsp;</p><p className="order-resume-item"> {/*}<p>récurrent jusqu'au {formatDate(props.order.order.regularEnd, "short")}</p>*/}régulier</p></div>
				</div>
			</div>
			<textarea
				id='precisions'
				className="text-input oneline"
				type="text"
				value={prec}
				onChange={(e) => setPrec(e.target.value)}
				placeholder={"Ajouter une précision (optionel)"}
				name="precisions"
			/>
			<div className="btn-group">
				<button
					id='orderer-btn'
					className='btn yellow lg'
					onClick={props.toggle}>
					Retour
				</button>
				{props.hasFreeOrder || props.subscription ?
					<button
						id='orderer-btn'
						className='btn green lg	'
						onClick={() => props.sendOrder(prec)}>
						Commander
					</button>
					:
					<p className='orderer-error'>Vous devez être abonné au service Explique-Moi Pythagore pour pouvoir commander un cours</p>
				}
			</div>
		</div>
	)
}

export default OrderResume
