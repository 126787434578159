import React from 'react'

export function getCookie(cname){
	var name = cname + "=";
	    var decodedCookie = decodeURIComponent(document.cookie);
	    var ca = decodedCookie.split(';');
	    for(var i = 0; i <ca.length; i++) {
	        var c = ca[i];
	        while (c.charAt(0) === ' ') {
	            c = c.substring(1);
	        }
	        if (c.indexOf(name) === 0) {
	            return c.substring(name.length, c.length);
	        }
	    }
	    return "";
}

export function formating(number){
		//check if a number (type string) has 0 figure, add 0 if just one
		const num = number.toString()
		if (num.length === 1){
			return '0'+num
		}else if(num.length === 2){
			return num
		}
		return num
	}

export function formatDate(fullDate, format="object"){
		let date = new Date(fullDate)
		let year = date.getFullYear()
		let month = date.getMonth()
		let day = date.getDate()
		let dateComplete = formating(day.toString()) + '/' + formating((month+1).toString()) + '/' + year.toString()
		let hour = date.getHours()
		let minute = date.getMinutes()
		let hourComplete = formating(hour.toString())+ 'h' + formating(minute.toString())
		if(format === "object"){
			return {date: dateComplete, hour: hourComplete}
		}else if(format === "string"){
			return hourComplete+"  "+dateComplete
		}else if(format === "short"){
			return date.toLocaleDateString()
		}
	}

export function getTime(_date, format="object"){
	const date = new Date(_date)
	if(format==="object"){
		return {hour: formating(date.getHours()), minute: formating(date.getMinutes())}
	}else if(format==="string"){
		return formating(date.getHours())+'h'+formating(date.getMinutes())
	}
}

export function formatAddress(address, format="oneline"){
		let precision = ''
		if (address.precision){
			precision = '('+address.precision+') '
		}
		if(format==="oneline"){
			return (precision+address.number+', '+address.street+'  \n'+address.city+' ('+address.zipcode+')').toLowerCase()
		}else if(format==="wrapped"){
			return (precision+address.number+', '+address.street+'  \n'+address.city+' ('+address.zipcode+')').toLowerCase()
		}
	}

export function formatProfile(profile, format="oneline"){
        if(format==="oneline"){
            return (profile.name + ": " + profile.level.name)
        }else if(format==="wrapped"){
            return (profile.name + ":\n" + profile.level.name)
        }else if(format==="description"){
            return (profile.name + " (" + profile.level.name + ")")
				}
    }

export function getKeyByValue(object, value) {
	  return Object.keys(object).find(key => object[key] === value);
	}


export function deOrD(word){
	const f = word[0]
	if(f === "a" ||f === "e" ||f === "i" ||f === "o" ||f === "u" ||f === "y" ||f === "A" ||f === "E" ||f === "I" ||f === "O" ||f === "U" ||f === "Y")
		return "d'"
	return <React.Fragment>{"de"}&nbsp;</React.Fragment>
}

export function add_minutes(dt, minutes) {
    return new Date(dt.getTime() + minutes*60000);
}

export function add_days(dt, days) {
    return new Date(dt.getTime() + days*24*60*60*1000);
}

export function	formatOrder(course){
		let order = {}
		order = Object.assign(order, course)
		order.time = getTime(order.date, "object")
		order.date = formatDate(order.date, "short")
		let status = ""
		if(order.status === "cancelled"){
			status = "Annulé"
			order.color = "red"
		}else if(order.status === "confirmed"){
			status = "À venir"
			order.color = "blue"
		}else if(order.status === "done"){
			status = "Effectué"
			order.color = "green"
		}else if(order.status === "ordered"){
			status = "Commandé"
			order.color = "yellow"
		}
		order.statusName = status
		return order
	}

export function renderOptions(list, type="general"){
		if(type==="general"){
			return list.map((item, i) => <option key={i} value={i}>{item.name}</option>)
		}else if(type==="addresses"){
			return list.map((item, i) => <option key={i} value={i}>{formatAddress(item)}</option>)
		}else if(type==="teachers"){
			return list.map((item, i) => <option key={i} value={i}>{item.firstname + " " + item.lastname}</option>)
    }else if(type==="profiles"){
      return list.map((item, i) => <option key={i} value={i}>{formatProfile(item, "description")}</option>)
    }
	}

export function renderAddresses(addresses, format="oneline", buttons=0, functions=undefined, labels=undefined){
		if(buttons === 0)
			return addresses.map((address, i) => <p key={i} className="item ">{formatAddress(address, format)}</p>)
		if(buttons === 2)
			return addresses.map(
				(address, i) =>
					<div key={i}className="row">
						<div className="item" style={{marginTop: "0.2em"}}>
							{" - " + formatAddress(address, format)}
							{/*<div className="btn link" onClick={() => functions[0](address)}>{labels[0]}</div>*/}
						</div>
						<div className="btn link red sm" onClick={() => functions[1](address)}>{labels[1]}</div>
					</div>
				)
	}


export function renderProfiles(profiles, format="oneline", buttons=0, functions=undefined, labels=undefined){
         if(buttons === 0)
             return profiles.map((profile, i) => <p key={i} className="item ">{formatProfile(profile, format)}</p>)
         if(buttons === 2)
             return profiles.map(
                 (profile, i) =>
                     <div key={i}className="row space-between">
                         <div className="item" style={{marginTop: "0.2em", textTransform: 'capitalize'}}>
                             {" - " + formatProfile(profile, format)}
                             {/*<div className="btn link" onClick={() => functions[0](address)}>{labels[0]}</div>*/}
                         </div>
                         <div className="btn link red sm" onClick={() => functions[1](profile)}>{labels[1]}</div>
                     </div>
                 )
     }

export function durationToString(duration){
		if(duration===1) return '1h00'
		if(duration===1.5) return '1h30'
		if(duration===2) return '2h00'
	}

export function formatTelNumber(n){
	return n[0]+n[1]+' '+n[2]+n[3]+' '+n[4]+n[5]+' '+n[6]+n[7]+' '+n[8]+n[9]
}

export function pretty_hour(d, precise=false, verbose=false){
    const secondes = d/1000
    const milli = floating_part(secondes)

    let pretty_days = secondes / (24 * 60 * 60)
    const hours = floating_part(pretty_days)
    let pretty_hours = hours * 24
    const minutes = floating_part(pretty_hours)
    let pretty_minutes = minutes * 60
    let pretty_secondes = floating_part(pretty_minutes) * 60

    pretty_days = Math.trunc(pretty_days)
    pretty_hours = Math.trunc(pretty_hours)
    pretty_minutes = Math.trunc(pretty_minutes)
    pretty_secondes = Math.trunc(pretty_secondes)

    let res = ''
    res += pretty_days !== 0 ? pretty_days + 'j ' : ''
    res += pretty_hours !== 0 ? pretty_hours + 'h ' : ''
    res += pretty_minutes !== 0 ? pretty_minutes + 'min ' : ''
	if(verbose) res += 'et '
    res += pretty_secondes !== 0 ? pretty_secondes + 'sec ' : ''
    if(precise) res += Math.trunc(milli*1000) + 'ms.'
    return res
}

const floating_part = (num) => num - Math.trunc(num)
