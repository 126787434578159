import React from 'react'
import axios from 'axios'

import Courses from './Courses.js'
import BigCourses from './BigCourses.js'
import SmallUserData from './SmallUserData.js'
import BigUserData from './BigUserData.js'
import OrderCard from '../Components/OrderCard'
//import Carrousel from './Carrousel'

import '../css/pupildashboard.css'

import { ScaleLoader } from 'react-spinners';


import logo from '../files/images/logo.png'

import { formatOrder } from '../functions/functions'

export default class TeacherDashboard extends React.Component{
	constructor(props){
		super(props)
		this.state={

		}

		this.shrinkAll = this.shrinkAll.bind(this)
		this.accept = this.accept.bind(this)
		this.reject = this.reject.bind(this)
		this.getCourses = this.getCourses.bind(this)
	}

	componentDidMount(){
		this.getCourses()
		this.handleThumbnails()
		setInterval(this.getCourses, 30000)
	}

	componentDidUpdate(prevProps){
		if (prevProps !== this.props) {
			this.handleThumbnails()
		}
	}

	handleThumbnails(){
		if(this.props.thumbnail === "courses"){
			this.setState({expandCourses: true, expandUserData: false})
		}else if(this.props.thumbnail === "userData"){
			this.setState({expandUserData: true, expandCourses: false})
		}else if(this.props.thumbnail === "main"){
			this.shrinkAll("handleThumbnails")
		}
	}

	getAddresses(){

	}

	getPupilsNames = (orders) => {
		let res = []
		orders.forEach((o, i) => {
			if(o.pupil && !res.includes(o.pupil)) res.push(o.pupil)
		})
		return res
	}

	getCourses(){
		const headers = {'Content-Type' : 'application/json',
							 'key': 'hello',
							 'id': this.props.auth.email,
							 'authorization': this.props.auth.token}
		axios.get(this.props.apiAddress+"/v2/orders", {headers: headers})
		.then((res) =>{
			let assigned = []
			let courses = res.data
			res.data.forEach((item, i, o)=>{
				if(item.status==="assigned"){
					assigned.push(item)
					o.splice(i, 1)
				}
			})
			this.setState({orders: courses.reverse(), assignedOrders: assigned, orderAssigned: assigned.length>0, pupilsNameList: this.getPupilsNames(courses), showLoader: false})
		}
		).catch((err) => {
			console.log(err)
			}
		)
	}

	shrinkAll(origin="unknown"){
		this.setState({expandUserData: false, expandCourses: false})
		if(origin === "handleThumbnails") return
		this.props.setThumbnail("main")
	}

	renderUserData(size="small"){
		if(size==="big")
			return <BigUserData
						utility={this.props.utility}
						preset={{topic: this.props.userData.lastTopic, level:this.props.userData.level}}
						apiAddress={this.props.apiAddress}
						userData={this.props.userData}
						auth={this.props.auth}
						updateData={(p) => this.props.updateData(p)}
						close={() =>this.shrinkAll()}
					/>
		return <SmallUserData
						utility={this.props.utility}
						preset={{topic: this.props.userData.lastTopic, level:this.props.userData.level}}
						apiAddress={this.props.apiAddress}
						userData={this.props.userData}
					/>
	}

	renderCourses(size="small"){
		if(size==="big"){
			return <BigCourses
				utility={this.props.utility}
				preset={{topic: this.props.userData.lastTopic, level:this.props.userData.level}}
				apiAddress={this.props.apiAddress}
				userData={this.props.userData}
				orders={this.state.orders}
				auth={this.props.auth}
				close={() =>this.shrinkAll()}
				update={() => this.getCourses()}
				interlocutorsList={this.state.pupilsNameList}
				   />
		}
		return <Courses
				utility={this.props.utility}
				preset={{topic: this.props.userData.lastTopic, level:this.props.userData.level}}
				apiAddress={this.props.apiAddress}
				userData={this.props.userData}
				orders={this.state.orders}
				auth={this.props.auth}
			/>
	}

	reject(id){
		const headers = {'Content-Type' : 'application/json',
						 'key': 'hello',
						 'authorization': this.props.auth.token,
						 'id': this.props.auth.email}
		axios.delete(this.props.apiAddress+"/v2/orders/"+id, {headers: headers})
		.then(() => {
			this.setState({showLoader: true})
			setTimeout(this.getCourses, 500)
			setTimeout(this.props.getPendingOrders, 500)
			this.setState({showConfirmModal: false})
		})
		.catch((err) => console.log(err))
	}

	accept(id){
		const headers = {'Content-Type' : 'application/json',
						 'key': 'hello',
						 'authorization': this.props.auth.token,
						 'id': this.props.auth.email}
		axios.patch(this.props.apiAddress+'/v2/orders/'+id, {data: 0}, {headers: headers})
		.then(() => {
			setTimeout(this.getCourses, 500)
			this.props.getPendingOrders()
			this.setState({showConfirmModal: false, showLoader: true})
		}).catch((err) => console.log(err))

	}

	toggleThumbnail(thbnl){
		if(thbnl === "courses"){
			this.props.setThumbnail("courses")
			this.setState({expandCourses: true, expandUserData: false})
		}else if(thbnl === "userData"){
			this.props.setThumbnail("userData")
			this.setState({expandUserData: true, expandCourses: false})
		}
	}

	renderConfirmModal(acceptF, rejectF){
		const fctn = this.state.currentCourse.accepted ? acceptF : rejectF
		const choice = this.state.currentCourse.accepted ? "accepter" : "refuser"
		return(
			<div className="frame-only bg-white column" style={{position: 'fixed', top: '30vh', left: '20vw', width: '60vw', height: '30vh', zIndex: 2000}}>
				<p style={{fontSize: '1.5em'}}>Confirmation</p>
				<p style={{fontSize: '1.2em'}}>Voulez-vous <strong>{choice}</strong> le cours ?</p>
				<div className="row">
					<div className="btn link red lg square" onClick={() => this.setState({showConfirmModal: false})}>
						<p>Non</p>
					</div>
					<div className="btn link green lg square" onClick={fctn}>
						<p>Oui</p>
					</div>
				</div>
			</div>
		)
	}

	renderPendingOrders(orders){
		return orders.map((order, i) =>
								<OrderCard
									key={i}
									data={formatOrder(order)}
									confirmable
                  					size={"huge teacherside"}
									userData={this.props.userData}
									auth={this.props.auth}
									selectCourseAndChoose={(decision) => this.setState({currentCourse: {courseId: order._id, accepted: decision}, showConfirmModal: true})}/>
)

	}

	render(){
		return(
			<React.Fragment>
				{this.state.showConfirmModal && this.renderConfirmModal( () => this.accept( this.state.currentCourse.courseId), () => this.reject(this.state.currentCourse.courseId))}
				{this.state.showLoader &&
					<div className="loader">
					<ScaleLoader
						className={'loader'}
						sizeUnit={"px"}
						height={60}
						width={10}
						color={'#4080ff'}
						loading={true}
					/>
					</div>
				}
				<img
					src={logo}
					id="dshbd-logo"
					className={this.state.expandCourses || this.state.expandUserData? "shrinker":"inactive"}
					alt=""
				/>
				{this.state.expandCourses? this.renderCourses("big"):null}
				{this.state.expandUserData? this.renderUserData("big"):null}
				<div className="pupildashboard">
					<div className="inner side left" onClick={() => this.toggleThumbnail("courses")}>
						<div className="content">
							{this.renderCourses()}
						</div>
						<div className="side-title left">
							<p>Gérer mes cours</p>
						</div>
					</div>
					<div className="inner center main" id="pendingOrders">
						<p className="title" >Demande(s) de cours</p>
						{this.props.pendingOrders?
							this.props.pendingOrders.length > 0?
								<div id="pending-orders-div">{this.renderPendingOrders(this.props.pendingOrders)}
										<br/>
										<br/>
										<br/>
										<br/>
								</div>

							:
								<div>
									<p>Aucune demande de cours pour le moment, mais un élève aura sûrement très vite besoin de vous !</p>

								</div>
						:
							null
						}
					</div>
					<div className="inner side right" onClick={() => this.toggleThumbnail("userData")}>
						<div className="content">
							{this.state.expandUserData? this.renderUserData("big"):this.renderUserData()}
						</div>
						<div className="side-title right">
							Modifier mes Infos
						</div>
					</div>
				</div>
			</React.Fragment>
			)
	}
}
