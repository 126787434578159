import React from 'react'
import axios from 'axios'
import {
    Link,
    Redirect
} from "react-router-dom"
import ReactGA from 'react-ga'
import MdEye from 'react-ionicons/lib/MdEye'
import MdEyeOff from 'react-ionicons/lib/MdEyeOff'

import ContactAndLegals from '../Components/ContactAndLegals'

import playStore from '../files/images/play_store.png'
import appStore from '../files/images/app_store.svg'

import pupil from '../files/images/eleve-assis.png'
import teacher from '../files/images/prof-visio.png'

import logo from '../files/images/logo.png'

import pepites from '../files/images/logo_pepites.png'
import peep from '../files/images/peep.jpeg'
//import idf from '../files/images/logo_idf.png'
import idf from '../files/images/LOGO_RIDF_2019.svg'
//import cci from '../files/images/logo_cci.png'
import cci from '../files/images/Logo_CCI_france.svg'
import stationf from '../files/images/Logo_STATION_F.svg'
import ministere from '../files/images/Ministre_de_lEnseignement_superieur.svg'


import '../css/login.css'

import conf from '../config.json'

console.log(conf.api)


export default class Login extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            email: "",
            pwd: "",
            emailSmall: "",
            showPwd: false,
            showForgotPassword: false,
            showAdForApp: true,
            showLegals: false
        }

        this.onChange = this.onChange.bind(this)
        this.login = this.login.bind(this)
    }

    componentDidMount(){
        ReactGA.pageview("Login");
        document.onkeyup = (e) => {
            if(e.which === 13){
                this.login()
            }
        }
    }

    onChange(event){
        this.setState({[event.target.name]: event.target.value})
    }

    async login(event){

        const data = { email: this.state.email,
            password: this.state.pwd
        }

        const headers = {'Content-Type' : 'application/json',
        'key': 'hello',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS'}
        if(event) {
            event.preventDefault()
            event.stopPropagation()
        }
        try {
            const res = await axios.post(conf.api+'/auth/signin', data, { headers: headers })
            // this.props.handleSession(true, {email: this.state.email, token: res.data.token, password: res.data.password}, '')
            console.log('res data', res.data)
            document.cookie = "loggedIn = true"
            document.cookie = "user = " + this.state.email
            document.cookie = "token = JWT " + res.data.token
            localStorage.setItem('token', `JWT ${res.data.token}`);
            document.cookie = "password = " + res.data.password
            this.setState({redirect: "/dashboard"})
            ReactGA.event({
                category: 'User',
                action: 'Logged in'
            })
        } catch (error) {
            console.log('Error', error)
            if (error.response && error.response.status === 401 && error.response.data.message === 'Email not verified') {
                alert('Email non confirmé ! Merci de confirmer votre email en cliquant sur le lien reçu lors de votre inscription')
            } else {
                alert('Erreur, vérifiez vos identifiants')
            }
        }
    }

    resetPassword(event){
        if(this.state.email.length !== 0){
            axios.post(conf.api+'/auth/forgot-password', {email: this.state.email})
            .then(alert('Un email vous a été envoyé, contenant un lien pour réinitialiser votre mot de passse'))
            .catch((error)=> {
                console.log(error.message);

            });
        }
        event.preventDefault()
        event.stopPropagation()
    }

    render(){
        return(
            <div className="container home-container">
                <img
                    src={pupil}
                    alt="pupil"
                    style={{
                        'position': 'fixed',
                        'bottom': '5em',
                        'right': '0',
                        'zIndex': '0',
                        'width': '45%'
                    }}
                />
                <img
                    src={teacher}
                    alt="teacher"
                    style={{
                        'position': 'fixed',
                        'bottom': '5em',
                        'left': '0',
                        'zIndex': '0',
                        'width': '45%'
                    }}
                />
                {this.state.showAdForApp &&
                    <div className="mobile-app">
                        Sur mobile?
                        <br/>
                        Pour une meilleure expérience, téléchargez notre application !
                        <div className="stores-badges">
                            <a
                                href="https://apps.apple.com/fr/app/explique-moi-pythagore/id1435151757"
                                rel="no_opener"
                                target="blank">
                                <img src={appStore} id="app-store" alt="" />
                            </a>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.empythagore.app&gl=FR"
                                rel="no_opener"
                                target="blank">
                                <img src={playStore} id="play-store" alt="" />
                            </a>
                        </div>
                        <button className="btn link blue" onClick={() => this.setState({showAdForApp: false})}>Fermer</button>
                    </div>
                }
                {this.state.redirect? <Redirect to={this.state.redirect} />: null}
                <div className="home-form-container" style={{
                    'zIndex': '10',}}>
                    <ContactAndLegals white/>
                    <img src={logo} className="home-logo" alt="logo" />
                    {!this.state.showForgotPassword?
                        <React.Fragment>
                            <h3>Connexion</h3>
                            <form onSubmit={(e) => this.login(e)}>
                                <input 	value={this.state.email}
                                    onChange={this.onChange}
                                    name='email'
                                    type="email"
                                    className="form-control home-form"
                                    aria-describedby="emailHelp"
                                    placeholder="Email"/>
                                    <small>{this.state.emailSmall}</small>
                                    <input 	value={this.state.pwd}
                                        onChange={this.onChange}
                                        name='pwd'
                                        type={this.state.showPwd? "text":"password"}
                                        className="form-control home-form"
                                        placeholder="Mot de Passe"/>
                                        <div type="button" className='showPwd'>
                                            {this.state.showPwd?
                                                <MdEye
                                                    onClick={() => this.setState({showPwd: !this.state.showPwd})}
                                                    fontSize="25px"
                                                    style={{cursor: "pointer"}}
                                                    color="#333"/>
                                                    :
                                                    <MdEyeOff
                                                        onClick={() => this.setState({showPwd: !this.state.showPwd})}
                                                        fontSize="25px"
                                                        style={{cursor: "pointer"}}
                                                        color="#333"/>
                                                    }
                                                </div>
                                                <small>
                                                    <button
                                                        className="btn link blue bold shadow"
                                                        href=""
                                                        type="button"
                                                        onClick={()=>this.setState({showForgotPassword: true})}>
                                                        J'ai oublié mon mot de passe
                                                    </button>
                                                </small>

                                                <br/>
                                                <button type='submit' id='homeButton' className='btn form-btn'>Connexion</button>
                                                <Link to="/register" id='registerButton' className="btn no-decoration form-btn">
                                                Inscription
                                            </Link>
                                        </form>

                                        <div className="login-footer row" style={{'position': 'fixed', 'right': '0'}}>
                                            <div className="partners-logos row">
                                                <img src={pepites} style={{height: "min-content", marginTop:"19px"}} className="partner-logo" alt="pepites" />
                                                <img src={idf} className="partner-logo" alt="idf" />
                                                <img src={ministere} style={{marginLeft: '-1.5em'}} className="partner-logo" alt="ministere" />
                                                <img src={cci} className="partner-logo" alt="cci" />
                                                <img src={stationf} className="partner-logo" alt="stationf" />
                                                <img src={peep} style={{height: "min-content", marginTop:"19px"}} className="partner-logo" alt="peep" />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <h3>Réinitialiser votre mot de passe</h3>
                                        <form onSubmit={(e) => this.resetPassword(e)}>
                                            <input 	value={this.state.email}
                                                onChange={this.onChange}
                                                name='email'
                                                type="email"
                                                className="form-control home-form"
                                                aria-describedby="emailHelp"
                                                placeholder="Email"/>
                                                <small>{this.state.emailSmall}</small>
                                                <br/>
                                                <p>Vous allez recevoir par mail un lien pour réinitialiser votre mot de passe à l'adresse renseignée ci dessus</p>
                                                <button type='submit' id='homeButton' className='btn form-btn'>Réinitialiser</button>
                                                <button className="btn link btn-blue" onClick={() =>this.setState({showForgotPassword: false})}>Retour</button>
                                                <br/><br/>
                                            </form>
                                        </React.Fragment>
                                    }

                                    <p className="btn link blue">v2.6.0</p>
                                </div>
                            </div>
                        )
                    }
                }
