import React from 'react'
import Rating from 'react-rating'
import axios from 'axios'

import full from '../files/images/svg/full-star.svg'
import empty from '../files/images/svg/empty-star.svg'
import '../css/rateTeacher.css'

const style={image: {padding: '1px', height: '2.5em'}}

export default class RatePupil extends React.Component{
	constructor(props){
		super(props)
		this.state={general: 3,
					serious: 3,
					mind: 3,
					comprehension: 3,
					course: 3,
					welcome:3,
					text: "",
					}
	}

	getRates(rate, id){
		this.setState({[id]: rate})
	}

	sendRates(){
		const data = {general: this.state.general,
					  serious: this.state.serious,
					  mind: this.state.mind,
					  comprehension: this.state.comprehension,
					  course: this.state.course,
					  welcome: this.state.welcome,
					  text: this.state.text,
					 }

		const headers = {'Content-Type' : 'application/json',
						 'key': 'hello',
						 'token': this.props.auth.token,
						 'id': this.props.auth.email}
		axios.post(this.props.apiAddress+"/pupil/"+this.props.course.createdBy+"/rate", 
			{rates: data, order_id: this.props.course._id}, 
			{headers: headers})
		.then((res) => {
			console.log(res)
			alert("Merci pour votre retour!")
			this.props.rated()
		})
		.catch((err) => console.log(err))
	}

	handleChange(event) {
    	this.setState({text: event.target.value});
  	}

  	small = (rate) => {
  		if(rate === 1){
  			return <p className="rater-small">Très insatisfaisant</p>
  		}else if(rate === 2){
  			return <p className="rater-small">Insatisfaisant</p>
  		}else if(rate === 3){
  			return <p className="rater-small">Bien</p>
  		}else if(rate === 4){
  			return <p className="rater-small">Très bien</p>
  		}else if(rate === 5){
  			return <p className="rater-small">Parfait</p>
  		}
  	}

	render(){
		return(
			<div className="frame rater">
				<p className="rater-title">Evaluation</p>
				<p className="rater-indication">Ces notes ne seront pas publiques, sont anonymes et seule l'équipe EMP y a accès.</p>
				<br/>

				<div className="criteria">
					<div className="title">
						<p className="main">Avis Général :</p>
						<p className="secondary"> votre satisfaction globale par rapport au déroulement du cours.</p>
					</div>
					<div className="rate">
						<Rating onChange={(rate) => this.getRates(rate, "general")}
								initialRating={this.state.general} 
								fullSymbol={<img alt='' src={full}   style={style.image} className="icon" />}
								emptySymbol={<img alt='' src={empty}  style={style.image}	 className="icon" />}/>
						{this.small(this.state.general)}
					</div>
					<br/>
				</div>

				<div className="criteria">
					<div className="title">
						<p className="main">État d'esprit :</p>
						<p className="secondary"></p>
					</div>
					<div className="rate">
						<Rating onChange={(rate) => this.getRates(rate, "mind")} 
								initialRating={this.state.mind} 
								fullSymbol={<img alt='' src={full}   style={style.image} className="icon" />}
								emptySymbol={<img alt='' src={empty}  style={style.image}	 className="icon" />}/>
						{this.small(this.state.mind)}
					</div>
				</div>

				<div className="criteria">
					<div className="title">
						<p className="main">Accueil :</p>
						<p className="secondary"></p>
					</div>
					<div className="rate">
						<Rating onChange={(rate) => this.getRates(rate, "welcome")} 
								initialRating={this.state.welcome} 
								fullSymbol={<img alt='' src={full}   style={style.image} className="icon" />}
								emptySymbol={<img alt='' src={empty}  style={style.image}	 className="icon" />}/>
						{this.small(this.state.welcome)}
					</div>
				</div>

				<div className="criteria">
					<div className="title">
						<p className="main">Sérieux : </p>
						<p className="secondary"></p>
					</div>
					<div className="rate">
						<Rating onChange={(rate) => this.getRates(rate, "serious")} 
								initialRating={this.state.serious} 
								fullSymbol={<img alt='' src={full}   style={style.image} className="icon" />}
								emptySymbol={<img alt='' src={empty}  style={style.image}	 className="icon" />}/>
						{this.small(this.state.serious)}
					</div>
				</div>

				<div className="criteria">
					<div className="title">
						<p className="main">Compréhension: </p>
						<p className="secondary"></p>
					</div>
					<div className="rate">
						<Rating onChange={(rate) => this.getRates(rate, "comprehension")} 
								initialRating={this.state.comprehension} 
								fullSymbol={<img alt='' src={full}   style={style.image} className="icon" />}
								emptySymbol={<img alt='' src={empty}  style={style.image}	 className="icon" />}/>
						{this.small(this.state.comprehension)}
					</div>
				</div>

				<div className="criteria">
					<div className="title">
						<p className="main">Déroulement: </p>
						<p className="secondary">votre satisfaction globale concernant le déroulement du cours.</p>
					</div>
					<div className="rate">
						<Rating onChange={(rate) => this.getRates(rate, "course")} 
								initialRating={this.state.course} 
								fullSymbol={<img alt='' src={full}   style={style.image} className="icon" />}
								emptySymbol={<img alt='' src={empty}  style={style.image}	 className="icon" />}/>
						{this.small(this.state.course)}
					</div>
				</div>
				
				{/*<div class="form-group">
				    <label for="exampleFormControlTextarea1">Commentaire</label>
				    <br/>
				    <textarea class="form-control" onChange={(e) => this.handleChange(e)} rows="3" value={this.state.text}></textarea>
				</div>*/}
				<button type="button" className="btn link green" onClick={() => this.sendRates()}>Envoyer</button>&nbsp;
				<br/>
				<br/>
				<br/>
			</div>
		)
	}
}