import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ReactGA from 'react-ga';
import MdArrowBack from 'react-ionicons/lib/MdArrowBack';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import AddAddress from '../Components/AddAddress';
import { renderAddresses} from '../functions/functions';

import '../css/subscription.css';
import peep from '../files/images/peep.jpeg'


const SubBtn = ({ subscription, price, handleSubscribe, handleTerminate, engaged, subtext }) => {
	console.log('subscription', subscription)
	if (subscription?.cancelled === true) {
		return (<div className={"subs-btn btn blue rounded"}>
			<p className='duration'>Votre abonnement sera résilié le</p>
			<p className='price'>{moment(subscription.next_renewal).format('DD/MM/YYYY')}</p>
		</div>)
	} else if (subscription?.status === 'active') {
		return (
			<div className={"subs-btn btn blue rounded"} onClick={handleTerminate}>
				<p className='duration'>Résilier mon abonnement</p>
			</div>

		)
	} else {
		return (
			<div className={"subs-btn btn blue rounded"} onClick={() => handleSubscribe(price)}>
				<p className='duration'>S'abonner pour</p>
				<p className='price'>{parseInt(price) / 100 + '€'} / mois</p>
				<p style={{margin: 0}}>{subtext}</p>
			</div>
		)
	}
};


export default class Subscriptions extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			clicked: false,
			billingAddress: this.props.userData.addresses[0],
		}
	}

	componentDidMount(){
		ReactGA.pageview("subscriptions")
		this.getPasses()
	}

	handleSubscribe = (price) => {
		this.setState({ clicked: true , price: price})
		setTimeout(() => document.getElementById('billing-address').scrollIntoView({behavior: 'smooth'}), 50);
	}

	handleTerminate = () => {
		confirmAlert({
			title: 'Voulez-vous résilier votre abonnement ?',
			message: 'Vous ne bénéficierez plus du service Explique-Moi Pythagore et ne pourrez donc plus commander de cours',
			buttons: [
				{
					label: 'Résilier',
					onClick: async () => {
						try {
							const headers = {
								'Accept': 'application/json',
								'Content-Type': 'application/json',
								'authorization': this.props.auth.token,
							};
							await axios.delete(this.props.apiAddress + '/v2/profile/subscribe', { headers });
							this.props.comeBack();
						} catch (err) {
							console.error(err);
						}
					}
				},
				{
					label: 'Non, je reste!',
					onClick: null
				}
			]
		});
	}

	getPasses = async () => {
		try {
			const headers = {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'authorization': this.props.auth.token,
			};
			// {"prices":{"engagedPrice":1500,"price":2500,"vipEngagedPrice":1200,"vipPrice":1500}}
			const { data: { prices }} = await axios.get(this.props.apiAddress + '/v2/profile/subscribe/price', { headers });
			this.setState({ prices });
		} catch (err) {
			console.error(err);
		}
	}

	pay = async () => {
		const headers = {
			'Accept': 'application/json',
			'Authorization': this.props.auth.token,
			'Content-Type': 'application/json'
		}
		let address = this.state.billingAddress
		delete address.valid
		const data = { address, price: this.state.price };
		const { data: { url } } = await axios.post(this.props.apiAddress + '/v2/profile/subscribe/form', data, { headers: headers })
		ReactGA.event({
			category: 'Subscription',
			action: 'clicked on subscribe',
		})
		window.open(url, "_blank");
		setTimeout(() => this.props.comeBack(), 1000);
	}

	render() {
		return(
			<div className="subs container" style={{textAlign: 'center'}}>
				<MdArrowBack
					onClick={()=>this.props.comeBack()}
					fontSize="30px"
					style={{position: "absolute", top: "10px", left:"10px", cursor: "pointer"}}
					color="#666"
				/>
				<p className="subs-title" >Abonnement</p>
				<p className="subs-description">Grâce à votre abonnement, profitez de manière illimitée du service Explique-Moi Pythagore : vous pourrez demander un professeur où vous voulez, quand vous voulez !</p>
				<div className={"subs-btns"}>
				<SubBtn
				engaged={false}
				price={this.state.prices?.vipPrice || this.state.prices?.price}
				subscription={this.props.userData.subscription}
				handleSubscribe={this.handleSubscribe}
				handleTerminate={this.handleTerminate}
				subtext="Sans engagement"
				/>
				<SubBtn
				engaged={true}
				price={this.state.prices?.vipEngagedPrice || this.state.prices?.engagedPrice}
				subscription={{active: false}}
				handleSubscribe={this.handleSubscribe}
				handleTerminate={this.handleTerminate} subtext="Engagement 10 mois + 2 mois offerts"
				/>
				{this.props.userData.subscription?.status === "active" &&
				<SubBtn
				engaged={false}
				price={this.state.prices?.vipPrice || this.state.prices?.price}
				subscription={{active: false}}
				handleSubscribe={this.handleSubscribe}
				handleTerminate={this.handleTerminate}
				subtext="Sans engagement"
				/>
			}
			</div>
				<p>- 20% pour les adhérents <a href="https://secondaire.peepsaintmaur.fr/fr/pourquoi-adherer">PEEP</a>, contactez nous pour bénéficier du tarif préférentiel.</p>
				<img src={peep} style={{height: "60px"}} alt="peep" />

				<br/>
				<br/>
				{this.state.clicked &&
					<div style={{maxWidth: '350px'}}>
					<p  id="billing-address">Adresse de facturation:</p>

					{/*ensure user has at least one address"*/}
					{this.state.billingAddress ?
						 renderAddresses([this.state.billingAddress], "wrapped", 0)
						 :
						 <p>Veuillez ajouter une adresse</p>
					}
						{!this.state.showAddressChooser ? (
							<button
								type="button"
								className={'btn link sm yellow'}
								onClick = {()=>this.setState({showAddressChooser: true})} //this.props.addAddress(this.state)
							>
								Changer mon adresse de facturation
							</button>
							) : (
								<div className="adresses container full-width" >
									<p> Choisissez une adresse de facturation</p>
									<hr className="full-width"/>
									<div className="addresses scroll-y">
										{this.props.userData.addresses && this.props.userData.addresses.length > 0 ?
											renderAddresses(
												this.props.userData.addresses,
												"wrapped",
												2,
												[(a) => this.setState({addressToChange: a}), (a) => this.setState({billingAddress: a, showAddressChooser: false})],
												["modifier", "sélectionner"]
											)
											:
											<p style={{textAlign:'center'}}>Merci de d'abord ajouter une adresse de facturation dans votre profil</p>
										}
									</div>
									<AddAddress
										address={this.state.addressToChange}
										addAddress={(a) => this.props.addAddress(a)}
										properlyRegistered={this.state.addressAdded}
									/>
								</div>
							)}
							<br/>
							<button className={this.state.billingAddress? "btn green square lg":"btn green square lg disabled"} type="button" name="payer" onClick={()=> this.pay()}>
							 Confirmer
							</button>
							<p id="payment-warning">Vous allez être redirigé vers la page de paiement de la banque (Si rien en se passe, vérifiez que votre navigateur n'a pas bloqué une fenêtre pop-up)</p>
							</div>
						}
					</div>
				)
	}
}
