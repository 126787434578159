import React from "react"

import { formatDate, renderAddresses, renderProfiles } from '../functions/functions'

import '../css/smalluserdata.css'

import defaultAvatar from '../files/images/Missing_avatar.png'

export default class SmallUserData extends React.Component{
	constructor(props){
		super(props)
		this.state= {}
	}

	render(){
		return(
			<div className="smalluserdata container">
				<p className="title">Mes Infos</p>
				<br/>
				<hr style={{width: "100%"}}/>
				<p className="item">{this.props.userData._id}</p>
				<div className="row container space-between">
					<div className="avatar">
						<img
							alt=""
							className="small"
							src={this.props.userData.avatar? this.props.userData.avatar:defaultAvatar}/>
					</div>
					<div className="textualElements">
						<p className="item">{this.props.userData.firstname}</p>
						<p className="item">{this.props.userData.lastname}</p>
						<p className="item">{this.props.userData.number}</p>
						<p className="item">{this.props.userData.level? this.props.userData.level.name:null	}</p>
					</div>
				</div>
				<hr style={{width: "100%"}}/>
				{this.props.userData.type === "pupil"?
					<React.Fragment>
						<p className='title'>Abonnement</p>
						<p className="item">
							{this.props.userData.subscription?.status === 'active' ?
								'Abonnement actif'
							:
								'Pas d\'abonnement'
							}
						</p>
						<hr style={{width: "100%"}}/>
						<p className='title'>Adresses</p>
						<div className="addresses">
							{this.props.userData.addresses.length > 0? renderAddresses(this.props.userData.addresses): <p style={{color: 'red'}}>Vous n'avez pas encore renseigné d'adresse</p>}
						</div>
            <hr style={{width: "100%"}}/>
						<p className='title'>Profils</p>
						<div className="addresses">
								{this.props.profiles && this.props.profiles.length > 0? renderProfiles(this.props.profiles): <p style={{color: 'red'}}>Vous n'avez pas encore ajouté de profil</p>}
						</div>
					</React.Fragment>
				:
					<div className="item topics">
						<p className='title'></p>
						{this.props.userData.topics?
							this.props.userData.topics.sort((a, b) => a.name.localeCompare(b.name)).map((item, i) => <p className="topic" key={i}>{item.name}</p>)
							:
							<p>Les matières que vous pourrez enseigner seront affichées ici une fois que nous les aurons ajoutées</p>}
					</div>
				}
			</div>
		)
	}
}
