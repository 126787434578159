import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Rating from 'react-rating'

import defaultAvatar from '../files/images/Missing_avatar.png'

import full from '../files/images/svg/full-star.svg'
import empty from '../files/images/svg/empty-star.svg'

import '../css/teacher-resume.css'

import config from '../config'
const api = config.api
/*
Displays résumé of teacher
Affiche le CV du prof
*/

export default function TeacherResume(props){

    const [teacherData, setTeacherData] = useState({})
    const [teacherPicture, setTeacherPicture] = useState()

    useEffect(() => {
        const headers = {
            'Content-Type' : 'application/json',
            'key': 'hello',
            'Access-Control-Allow-Origin' : props.apiAddress+'/profile',
            'id': props.teacher,
            'authorization': props.auth.token
        }
        axios.get(api+'/teachers/'+props.teacher,
        {headers: headers})
        .then((doc) => {
            setTeacherData(doc.data)
            props.setTeacher(doc.data.firstname)
            const img64 = 'data:image/png;charset=utf-8;base64, '+doc.data.picture
            setTeacherPicture(img64)
            console.log(doc.data.infos)

        }).catch(function (err) {
            console.log(err);
        })
    }, [props.teacher])

    return(
        <div className="teacher-resume">
            <div className="row full-width">
                <div className="row"  style={{display: 'flex', flex: 1, justifyContent: "space-around"}}>
                    <p className="title" style={{marginLeft: '5px', fontWeight:"bold"}}>{teacherData.firstname? teacherData.firstname:'Chargement...'}</p>
                    <div>
                    <p>Note:</p>
                    <Rating
                        readonly
                        initialRating={teacherData? teacherData.rate: "Chargement.."}
                        fullSymbol={<img alt='' src={full}   style={style.image} className="icon" />}
                        emptySymbol={<img alt='' src={empty}  style={style.image} className="icon" />}
                    />
                    </div>
                </div>
                <img style={{maxWidth: '50%', maxHeight: '7em'}} alt="" className="avatar" src={teacherPicture? teacherPicture:defaultAvatar} />
            </div>
            <div className="column full-width" style={{margin: '1px'}}>
                {teacherData.infos && <p style={{fontStyle: 'italic', fontSize: '0.8em', width: '100%'}}>Parcours du professeur:</p>}
                {teacherData.infos ? Object.keys(teacherData?.infos).map((key, i) => (
                    <div key={i} style={{ marginBottom: '2px', textAlign: 'left', alignItem: 'left', width: '100%'}}>
                        <p className="resume-label" style={{ fontWeight: 'bold', margin: '1px', textTransform: 'capitalize'}}>{teacherData?.infos[key] && key}</p>
                        <p style={{ margin: '1px' }}>{teacherData?.infos[key]}</p>
                    </div>
                ))
                :
                null}
            </div>
        </div>
    )
}

const style={image: {padding: '1px', height: '2.5em'}}
