import React from 'react'
import axios from 'axios'
import {
    Redirect
} from "react-router-dom"
import ContactAndLegals from '../Components/ContactAndLegals'

import ReactGA from 'react-ga'
import MdCalendar from 'react-ionicons/lib/MdCalendar'
import IosContact from 'react-ionicons/lib/IosContact'
import IosPowerOutline from 'react-ionicons/lib/IosPowerOutline'
import IosVideocam from 'react-ionicons/lib/IosVideocam'

import PupilDashboard from '../Containers/PupilDashboard'
import TeacherDashboard from '../Containers/TeacherDashboard'

import { getCookie, add_days } from '../functions/functions.js'

import { ScaleLoader } from 'react-spinners';


import '../css/dashboard.css'

import toque from '../files/images/toque.png'
import logo from '../files/images/logo.png'


export default class Dashboard extends React.Component{
    constructor(props){
        super(props)
        this.state={
            auth: {},
            thumbnail: "main",
            showNumber: false,
            showEmail: false,
            cantLogin: 0,
        }

        this.getProfile = this.getProfile.bind(this)
        this.getAvatar = this.getAvatar.bind(this)
        this.getPendingOrders = this.getPendingOrders.bind(this)
    }

    componentDidMount(){
        window.scrollTo(0, 0)
        let email = getCookie("user")
        let token = getCookie("token")
        let pwd = getCookie("password")
        this.setState({auth: {email: email, token: token, password: pwd}}, this.getProfile)
    }

    getProfile(refresh="all"){
        const headers = {
            'Content-Type' : 'application/json',
            'key': 'hello',
            'Access-Control-Allow-Origin' : this.props.apiAddress+'/v2/profile',
            'id':this.state.auth.email,
            'authorization': this.state.auth.token
        }
        axios.get(this.props.apiAddress+'/v2/profile',
        {headers: headers	})
        .then((doc) => {
            this.setState({userType: doc.data.type, userData:doc.data, gotAPIResponse: true}, () => this.getAvatar())
            ReactGA.pageview("dashboard("+this.state.userType+")")
            if(refresh === "all"){
                this.getTopicAndLevels()
                if(doc.data.level){
                    this.setState({
                        level: {
                            _id: doc.data.level._id,
                            name: doc.data.level.name,
                            price: doc.data.level.price,
                            remotePrice: doc.data.level.remotePrice
                        }
                    })
                }
            }
            if(doc.data.type === 'teacher'){
                this.getPendingOrders()
            }
        })
        .catch((err) => {
            console.log(err)
            if(this.state.cantLogin > 3) {
                this.setState({redirect: "login"})
            }else{
                this.setState((state) => {
                    return { ...state, cantLogin: 1 + state.cantLogin}
                }, this.getProfile)
            }
        })
    }

    getAvatar(){
        const headers = {'response-type': 'arraybuffer',
        'key': 'hello',
        'token': this.state.auth.token,
        'id': this.state.auth.email}

        axios.get(this.props.apiAddress+"/getPicture", {headers: headers})
        .then((res) => {
            const img64 = 'data:image/png;charset=utf-8;base64, '+res.data.data
            this.setState({userData: {...this.state.userData, avatar: img64}})
        }).catch(function (err) {
            console.log(err)
        });
    }

    getTopicAndLevels(){
        let headers = {'Content-Type' : 'application/json',
        'key': "hello",
        'Access-Control-Allow-Origin' : this.props.apiAddress+'/levels',
        'id':this.state.auth.email,
        'authorization': this.state.auth.token}
        axios.get(this.props.apiAddress+'/levels',
        {headers: headers})
        .then((res) => {
            let levels = res.data
            let formated = []
            for(let i in levels){
                formated.push({_id: levels[i]._id,
                    name: levels[i].name,
                    price: levels[i].price,
                    remotePrice: levels[i].remotePrice,
                })
            }
            this.setState({levels: formated})
            //this.createSelectLevelMenu(formated)
        }).catch(function (err) {
            console.log(err);
        });

        headers = {'Content-Type' : 'application/json',
        'key': this.state.key,
        'Access-Control-Allow-Origin' : this.props.apiAddress+'/topics',
        'authorization':this.state.auth.email}
        axios.get(this.props.apiAddress+'/topics',
        {headers: headers})
        .then((res) => {
            let topics = res.data
            let formated = []
            let reversed = {}
            for(let i in topics){
                formated.push({_id: topics[i]._id,
                    name: topics[i].name})
                    reversed[topics[i]._id] = topics[i].name
                }
                this.setState({topics: formated})
                //this.createSelectTopicMenu(formated)
            }).catch(function (err) {
                console.log(err);
            });
        }

        getPendingOrders(){
            let headers = {'Content-Type' : 'application/json',
            'key': this.state.key,
            'Access-Control-Allow-Origin' : 'https://'+this.props.apiAdress+'/levels',
            'id':this.state.auth.email,
            'authorization': this.state.auth.token}
            axios.get(this.props.apiAddress+'/v2/orders/pending',
            {headers: headers	})
            .then((doc)=> {
                this.setState({pendingOrders: doc.data})
            })
            .catch((err)=>{
                console.log(err)
            })
        }

        setThumbnail(thbnl){
            this.setState({thumbnail: thbnl})
        }

        logout(){
            this.setState({redirect: "login"})
            document.cookie = "loggedIn = jsp"
            document.cookie = "user = gilbert"
            document.cookie = "password = secret"
            document.cookie = "token = took take token ??"
        }

        renderPassCTA = (subscription) => {
            let color = subscription?.status === 'active' ? 'green' : 'yellow';
            let text = subscription?.status === 'active' ? 'Abonnement actif' : 'Pas d\'abonnement';

            return (
                <button
                    id='pass-status-btn'
                    onClick={() => this.setState({redirect: 'dashboard/passes', thumbnail: 'userData'})}
                    className={'btn '+ color +' lg rounded'}>
                    {text}
                </button>
            )
        }

        render(){
            return(
                <div className="dashboard-container">
                    <div className="dshbd-bg">
                        <img src={require(this.state.userType==="pupil"? "../files/images/eleve-assis.png":"../files/images/prof-visio.png")  }
                        alt=''
                        style={this.state.userType==="pupil"?
                        {position: 'fixed',
                        bottom: 0,
                        right: 0,
                        width: '50vw',}
                        :
                        {position: 'fixed',
                        bottom: 0,
                        left: 0,
                        width: '50vw',}
                    }/>
                </div>
                {this.state.redirect && <Redirect from={"dashboard/passes"} to={this.state.redirect} />}
                {this.state.cantLogin > 1? <div style={{position: 'absolute', top: "30vh", height: "40vh"}}>Erreur: vérifiez votre connexion internet. Si l'erreur persiste, nous allons vous rediriger vers la page de connexion.</div>: null}
                <div className="dshbd-band head">
                    <div className="row" style={{alignItems: 'center'}}>
                        <button className="btn transparent" onClick={()=>this.setState({thumbnail: 'main'})}>
                            <img
                                alt=""
                                className={this.state.thumbnail === 'main'? "logo foot icon active":"logo foot icon inactive" }
                                src={logo}
                            />
                        </button>
                        <ContactAndLegals relativePosition horizontalLayout white noLegals/>
                    </div>
                    {/*this.state.userType === "teacher" &&
                    <a className='btn green row sm rounded' id="visio-shortcut" href={`https://whereby.com/emp-${this.state.auth.email}`} target="_blank" rel="noopener noreferrer">
                    <p style={{fontSize: '1.1em'}}>Accéder à la visio&nbsp;</p>
                    <IosVideocam
                    color={'#fff'}
                    title={'Visio'}
                    style={{cursor: "pointer", marginTop: '2px'}}
                    fontSize="25px"
                />
            </a>
            */}
            <div className="row">
                {this.state.userData && this.state.userType === "pupil" &&  this.renderPassCTA(this.state.userData.subscription)}
                <div id="logout" style={{alignItems: 'center'}}>
                    <IosPowerOutline
                        onClick={()=>this.logout()}
                        fontSize="25px"
                        style={{cursor: "pointer"}}
                        color="#fff"
                    />
                    {/*}<p className="tooltip">Déconnexion</p>*/}
                </div>
            </div>
        </div>

        <div className="dshbd-body">
            {this.state.auth.token !== ""?
            this.state.gotAPIResponse && this.state.levels && this.state.topics?
            this.state.userType==="pupil"?
            <PupilDashboard
                auth={this.state.auth}
                utility={{topics: this.state.topics, levels: this.state.levels}}
                userData={this.state.userData}
                apiAddress={this.props.apiAddress}
                updateData={this.getProfile}
                thumbnail={this.state.thumbnail}
                setThumbnail={(t)=>this.setThumbnail(t)}
            />
            :
            <TeacherDashboard
                auth={this.state.auth}
                utility={{topics: this.state.topics, levels: this.state.levels}}
                userData={this.state.userData}
                apiAddress={this.props.apiAddress}
                updateData={this.getProfile}
                thumbnail={this.state.thumbnail}
                setThumbnail={(t)=>this.setThumbnail(t)}
                pendingOrders={this.state.pendingOrders}
                getPendingOrders={this.getPendingOrders}
            />
            :
            <div className={' loader'}>
                <ScaleLoader
                    className={' loader'}
                    sizeUnit={"px"}
                    height={30}
                    width={5}
                    color={'#4080ff'}
                    loading={true}
                />
            </div>
            :
            <Redirect to="/login" />
        }
    </div>
    <div className="dshbd-band foot">
        <MdCalendar
            onClick={()=>this.setState({thumbnail: 'courses'})}
            fontSize="35px"
            style={{cursor: "pointer"}}
            color={this.state.thumbnail === 'courses'? "#ffffff":"#999" }/>
            <img
                alt=""
                className={this.state.thumbnail === 'main'? "foot icon active":"foot icon inactive" }
                src={toque}
                onClick={()=>this.setState({thumbnail: 'main'})}/>
                <IosContact
                    onClick={()=>this.setState({thumbnail: 'userData'})}
                    fontSize="35px"
                    style={{cursor: "pointer"}}
                    color={this.state.thumbnail === 'userData'? "#ffffff":"#999" }/>
                </div>

            </div>
        )
    }
}
