import React from 'react'
import axios from 'axios'

import Orderer from './Orderer'
import Courses from './Courses.js'
import BigCourses from './BigCourses.js'
import SmallUserData from './SmallUserData.js'
import BigUserData from './BigUserData.js'
import TeacherCarrousel from '../Components/TeacherCarrousel'

import { ScaleLoader } from 'react-spinners';


//import Carrousel from './Carrousel'

import '../css/pupildashboard.css'

//import { BarLoader,ScaleLoader } from 'react-spinners';
//import { formatOrder } from '../functions/functions'


import logo from '../files/images/logo.png'


export default class PupilDashboard extends React.Component{
	constructor(props){
		super(props)
		this.state={
			profiles: null,
			carrouselIndex: 0,
		}

		this.shrinkAll = this.shrinkAll.bind(this)
		//this.accept = this.accept.bind(this)
		//this.reject = this.reject.bind(this)
		this.getCourses = this.getCourses.bind(this)
		this.interval = 0
	}

	componentDidMount(){
		this.getProfiles()
		this.getCourses()
		this.handleThumbnails()
		this.interval = setInterval(this.getCourses, 10000)
	}

	componentDidUpdate(prevProps){
		if (prevProps !== this.props) {
			this.handleThumbnails()
		}
	}

	componentWillUnmount(){
		clearInterval(this.interval)
	}

	handleThumbnails(){
		if(this.props.thumbnail === "courses"){
			this.setState({expandCourses: true, expandUserData: false})
		}else if(this.props.thumbnail === "userData"){
			this.setState({expandUserData: true, expandCourses: false})
		}else if(this.props.thumbnail === "main"){
			this.shrinkAll("handleThumbnails")
		}
	}

	filter = (orders) => {
		let res = []
		orders.forEach((o, i) => {
			if(o.status === "ordered" && o.acceptedBy?.length > 0) res.push(o)
		})
		return res
	}

	getTeachersNames = (orders) => {
		let res = []
		orders.forEach((o, i) => {
			if(o.teacherName && !res.includes(o.teacherName)) res.push(o.teacherName)
		})
		return res
	}

	getCourses(show_loader=false){
		if(show_loader) this.setState({showLoader: true})
		const headers = {'Content-Type' : 'application/json',
		'key': 'hello',
		'id': this.props.auth.email,
		'authorization': this.props.auth.token}
		axios.get(this.props.apiAddress+"/v2/orders", { headers })
		.then((res) =>{
			const assorders = this.filter(res.data)
			this.setState({orders: res.data.reverse(), assignedOrders: assorders, orderAssigned: assorders.length>0, showLoader: false, teachersNameList: this.getTeachersNames(res.data)})
		})
		.catch((err) => {
			console.log(err)
		})
	}

	getProfiles(){
		const headers = {'Content-Type' : 'application/json',
		'key': 'hello',
		'id': this.props.auth.email,
		'authorization': this.props.auth.token}
		axios.get(this.props.apiAddress+"/v2/profiles", {headers: headers})
		.then((res) =>{
			this.setState({profiles: res.data})
		})

	}

	shrinkAll(origin="unknown"){
		this.setState({expandUserData: false, expandCourses: false})
		if(origin === "handleThumbnails") return
		this.props.setThumbnail("main")
	}

	renderUserData(size="small"){
		if(size==="big")
		return <BigUserData
			utility={this.props.utility}
			preset={{topic: this.props.userData.lastTopic, level:this.props.userData.level}}
			apiAddress={this.props.apiAddress}
			userData={this.props.userData}
			auth={this.props.auth}
			updateData={(p) => this.props.updateData(p)}
			updateProfiles={() => this.getProfiles()}
			close={() => this.shrinkAll()}
			profiles={this.state.profiles}
			   />
		return <SmallUserData
			utility={this.props.utility}
			preset={{topic: this.props.userData.lastTopic, level:this.props.userData.level}}
			apiAddress={this.props.apiAddress}
			userData={this.props.userData}
			profiles={this.state.profiles}
			   />
	}

	renderCourses(size="small"){
		if(size==="big"){
			return <BigCourses
				utility={this.props.utility}
				preset={{topic: this.props.userData.lastTopic, level:this.props.userData.level}}
				apiAddress={this.props.apiAddress}
				userData={this.props.userData}
				orders={this.state.orders}
				auth={this.props.auth}
				close={() => this.shrinkAll()}
				update={() => this.getCourses()}
				interlocutorsList={this.state.teachersNameList}
				   />
		}
		return <Courses
			utility={this.props.utility}
			preset={{topic: this.props.userData.lastTopic, level:this.props.userData.level}}
			apiAddress={this.props.apiAddress}
			userData={this.props.userData}
			orders={this.state.orders}
			auth={this.props.auth}
			   />
	}

	toggleThumbnail(thbnl){
		if(thbnl === "courses"){
			this.props.setThumbnail("courses")
			this.setState({expandCourses: true, expandUserData: false})
		}else if(thbnl === "userData"){
			this.props.setThumbnail("userData")
			this.setState({expandUserData: true, expandCourses: false})
		}
	}

	renderConfirmModal(acceptF, rejectF, type='ce cours'){
		const fctn = this.state.currentCourseAccepted ? acceptF : rejectF
		const choice = this.state.currentCourseAccepted ? "accepter" : "refuser"
		return(
			<div className="frame-only bg-white column" style={{position: 'fixed', top: '30vh', left: '20vw', width: '60vw', height: '30vh', zIndex: 2000}}>
				<p style={{fontSize: '1.5em'}}>Confirmation</p>
				<p style={{fontSize: '1.2em'}}>Voulez-vous <strong>{choice}</strong> {type} ?</p>
				<div className="row">
					<div className="btn link yellow lg square" onClick={() => this.setState({showConfirmModal: false})}>
						<p>Annuler</p>
					</div>
					<div className="btn link green lg square" onClick={fctn}>
						<p>Oui</p>
					</div>
				</div>
			</div>
		)
	}

	render(){
		return(
			<React.Fragment>
				{this.state.showLoader && <ScaleLoader
					className={' loader'}
					sizeUnit={"px"}
					height={30}
					width={5}
					color={'#4080ff'}
					loading={true}
										  />
				}
				{this.state.showConfirmModal && this.renderConfirmModal( () => this.accept(), () => this.reject(), "ce professeur" )}
				<img
					src={logo}
					id="dshbd-logo"
					className={this.state.expandCourses || this.state.expandUserData? "shrinker":"inactive"}
					alt=""
				/>
				{this.state.expandCourses? this.renderCourses("big"):null}
				{this.state.expandUserData? this.renderUserData("big"):null}
				<div className="pupildashboard">
					{/*<Carrousel
						utility={this.props.utility}
						preset={{topic: this.props.userData.lastTopic, level:this.props.userData.level}}
						apiAddress={this.props.apiAddress}
						userData={this.props.userData}
						coursesData={{orders: this.state.orders}}
					/>*/}
					<div className="inner side left" onClick={() => this.toggleThumbnail("courses")}>
						<div className="content">
							{this.renderCourses()}
						</div>
						<div className="side-title left">
							<p>Gérer mes cours</p>
						</div>
					</div>
					{this.state.assignedOrders?.map((o, i) =>
						<TeacherCarrousel
							index={this.state.carrouselIndex}
							order={o}
							apiAddress={this.props.apiAddress}
							auth={this.props.auth}
							userData={this.props.userData}
							setState={(s) => this.setState(s)}
							updateCourses={() => this.getCourses()}
						/>
					)}
					{this.state.assignedOrders?.length ===0 &&
						<div className="inner center main scroll-y">
							{this.state.profiles !== null?
								<Orderer
									utility={this.props.utility}
									preset={{topic: this.props.userData.lastTopic, level:this.props.userData.level}}
									apiAddress={this.props.apiAddress}
									userData={this.props.userData}
									profiles={this.state.profiles}
									auth={this.props.auth}
								/>
							:
							<>Chargement..</>}
						</div>
					}
					<div className="inner side right" onClick={() => this.toggleThumbnail("userData")}>
						<div className="content">
							{this.state.expandUserData? this.renderUserData("big"):this.renderUserData()}
						</div>
						<div className="side-title right">
							Modifier mes Infos
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}
