import React from 'react'
import axios from 'axios'
import {
    Redirect,
    withRouter
} from "react-router-dom"

import ContactAndLegals from '../Components/ContactAndLegals'

import { ScaleLoader } from 'react-spinners';

import MdEye from 'react-ionicons/lib/MdEye'
import MdEyeOff from 'react-ionicons/lib/MdEyeOff'

import logo from '../files/images/logo.png'

import '../css/reset-password.css'


class ResetPassword extends React.Component{
    constructor(props){
        super(props)
        console.log(props)
        this.state ={
            password: "",
            confirmPassword: "",
            showPassword: false,
            showConfirmPassword: false,
            redirect: props.match.params.token ? null : '/login',
            error: null
        }
    }

    onChange = (event) => {
        this.setState({[event.target.name]: event.target.value}, this.checkPassword)
    }

    checkPassword = ()	=> {
        let passwd = ""
        for(let i =0; i<2; i++){
            passwd = this.state.password
            if(passwd.length > 7){
                let strength = 0
                let hasMin = false
                let hasMaj = false
                let hasFigure = false
                let hasSpecial = false
                for(let i=0; i<passwd.length; i++){
                    if(passwd[i] >= 'a' && passwd[i] <= 'z' && !hasMin){
                        strength++
                        hasMin = true
                    }
                    if(passwd[i] >= '0' && passwd[i] <= '9' && !hasFigure){
                        strength++
                        hasFigure = true
                    }
                    if(passwd[i] >= 'A' && passwd[i] <= 'Z' && !hasMaj){
                        strength++
                        hasMaj = true
                    }
                    if((passwd[i] < '0' || passwd[i] > '9' || passwd[i] < 'A' || passwd[i] > 'Z' || passwd[i] < 'a' || passwd[i] > 'z') && !hasSpecial){
                        strength++
                        hasSpecial = true
                    }
                }
                if(strength===2){
                    this.setState({ pwdStrength: 'Sécurité: Bonne', pwdFrame: 'valid', isPwdOk: true })
                }else if(strength===3){
                    this.setState({ pwdStrength: 'Sécurité: Forte', pwdFrame: 'valid', isPwdOk: true })
                }else if(strength===4){
                    this.setState({ pwdStrength: 'Sécurité: Excellente', pwdFrame: 'valid', isPwdOk: true })
                }else{
                    this.setState({ pwdStrength: 'Sécurité: Moyenne' , pwdFrame: 'valid', isPwdOk: true })
                }
            } else {
                this.setState({ pwdStrength: '8 caractères minimum', pwdFrame:'invalid', isPwdOk: false })
            }
        }
        if (this.state.confirmPassword !== '' && this.state.password !== this.state.confirmPassword && this.state.password.indexOf(this.state.confirmPassword) < 0) {
            this.setState({ error: 'Les mots de passes no correspondent pas' })
        } else {
            this.setState({ error: null })
        }
    }

    resetPassword = (event) => {
        if (!this.state.error && this.state.pwdFrame === 'valid' && this.state.password !== '' && this.state.password === this.state.confirmPassword) {
            const headers = {
                'Content-Type' : 'application/json',
                'key': 'hello',
                'Accept': 'application/json',
                'Authorization' : 'JWT ' + this.props.match.params.token
            }
            this.setState({loader:
                <ScaleLoader
                    className={' loader'}
                    sizeUnit={"px"}
                    height={30}
                    width={5}
                    color={'#4080ff'}
                    loading={true}
                />
            })
            axios.post(this.props.apiAddress+'/v2/auth/reset-password', { password: this.state.password }, { headers })
            .then(() => {
                alert('Votre mot de passe a bien été réinitialisé. Vous pouvez maintenant vous connecter')
                this.setState({redirect: "/login", loader: undefined})
            })
            .catch((error)=> {
                console.log(error.message);
            });
        }
        event.preventDefault()
        event.stopPropagation()
    }

    render(){
        return(
            <div className="container home-container">
                {this.state.redirect? <Redirect to={this.state.redirect} />: null}
                <img src={logo} className="home-logo" alt="logo" />
                {!this.state.showForgotPassword?
                    <React.Fragment>
                        <h3>Réinitialiser mon mot de passe</h3>
                        <form onSubmit={this.resetPassword}>
                            <input
                                value={this.state.password}
                                onChange={this.onChange}
                                name='password'
                                type={this.state.showPassword ? "text" : "password"}
                                className="form-control home-form"
                                placeholder="Mot de passe"/>
                                <div type="button" className='showPwd'>
                                    {!this.state.showPassword ? <MdEye
                                        onClick={() => this.setState({ showPassword: true })}
                                        fontSize="25px"
                                        color="#333"/> : <MdEyeOff
                                            onClick={() => this.setState({ showPassword: false })}
                                            fontSize="25px"
                                            color="#333"/>
                                        }
                                    </div>
                                    <input
                                        value={this.state.confirmPassword}
                                        onChange={this.onChange}
                                        name='confirmPassword'
                                        type={this.state.showConfirmPassword ? "text" : "password"}
                                        className="form-control home-form"
                                        placeholder="Confirmation mot de passe"/>
                                        <div type="button" className='showPwd'>
                                            {!this.state.showConfirmPassword ? <MdEye
                                                onClick={() => this.setState({ showConfirmPassword: true })}
                                                fontSize="25px"
                                                color="#333"/> : <MdEyeOff
                                                    onClick={() => this.setState({ showConfirmPassword: false })}
                                                    fontSize="25px"
                                                    color="#333"/>
                                                }
                                            </div>
                                            <p style={{ color: this.state.error ? 'red' : 'blue' }}>{this.state.error ? this.state.error : this.state.pwdStrength}</p>
                                            <br/><br/>
                                            {this.state.loader?
                                                this.state.loader
                                                :
                                                <button type='submit' id='homeButton' className='btn form-btn'>Changer mon mot de passe</button>
                                            }
                                        </form>
                                        <ContactAndLegals />
                                        <div className="login-footer-reset-pwd">
                                            <p className="btn link white">v2.0.3</p>
                                            <a href="https://blog.expliquemoipythagore.fr/wp-content/uploads/2020/03/RGPD.pdf" target="_blank" rel="noopener noreferrer" className="btn link white">RGPD</a>
                                            <a target="_blank" rel="noopener noreferrer" href="https://blog.expliquemoipythagore.fr/wp-content/uploads/2020/03/CGU.pdf" className="btn link white">CGU</a>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <h3>Réinitialiser votre mot de passe</h3>
                                        <form onSubmit={(e) => this.resetPassword(e)}>
                                            <input 	value={this.state.email}
                                                onChange={this.onChange}
                                                name='email'
                                                type="email"
                                                className="form-control home-form"
                                                aria-describedby="emailHelp"
                                                placeholder="Email"/>
                                                <small>{this.state.emailSmall}</small>
                                                <br/>
                                                <p>Vous allez recevoir par mail un lien pour réinitialiser votre mot de passe à l'adresse renseignée ci dessus</p>
                                                <button type='submit' id='homeButton' className='btn form-btn'>Réinitialiser</button>

                                                <button className="btn link btn-blue" onClick={() =>this.setState({showForgotPassword: false})}>Retour</button>
                                                <br/><br/>
                                            </form>
                                        </React.Fragment>
                                    }
                                </div>
                            )
                        }
                    }

                    export default withRouter(ResetPassword)
