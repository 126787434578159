import React from 'react'

import OrderCard from '../Components/OrderCard'

import { ScaleLoader } from 'react-spinners';

import '../css/courses.css'

import { formatOrder, getTime } from '../functions/functions'

export default class Courses extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			expand: false,
			activeCourseIndex: 0,
			filter: 'none',
		}

	}

	componentDidMount = () => {
		setTimeout( () => {
			const cursor = document.getElementById('sm-today-cursor')
			if(cursor) cursor.scrollIntoView({behavior: 'smooth'})
		}, 1000)
	}

	renderOrders(format="card"){
		const now = new Date()
		let prevDate = 0
		let courses = this.filterOrders(this.props.orders)
		if(format==="buttons"){
			return courses.map((course, i) =>
											<div onClick={() => this.setState({activeCourseIndex: i})}>
												{/*les cours sont dans le sens anti-chronologique*/}
												{ ( !(new Date(prevDate) > now) && !(now > new Date(course.date))) && <p id="sm-today-cursor">Aujourd'hui</p>}
												<p style={{display: 'none'}}>{prevDate = course.date}</p>
												<OrderCard
													key={i}
													data={formatOrder(course)}
													small
													apiAddress={this.props.apiAddress}
													userData={this.props.userData}
													auth={this.props.auth}/>
											</div>)
		}
		return courses.map((course, i) =>
		 	<div key={i}>
				{/*les cours sont dans le sens anti-chronologique*/}
				{ ( !(new Date(prevDate) > now) && !(now > new Date(course.date))) && <p id="sm-today-cursor">Aujourd'hui, {getTime(now, 'string')}</p>}
				<p style={{display: 'none'}}>{prevDate = course.date}</p>
				<OrderCard
					key={i}
					data={formatOrder(course)}
					auth={this.props.auth}
					userData={this.props.userData}
					apiAddress={this.props.apiAddress}
				/>
			</div>)
	}

	filterOrders(courses){
		if(this.state.filter!=='none'){
			return courses.filter((course) => course.status===this.state.filter)
		}
		return courses
	}




	render(){
		return(
			<React.Fragment>
					<div className="courses container">
						<p className="title">Mes Cours</p>
						{/*}<div className="courses legend">
							<div className="courses legend item yellow" onClick={() => this.setState({filter: 'ordered'})}>
								En attente
							</div>
							<div className="courses legend item red" onClick={() => this.setState({filter: 'cancelled'})}>
								Annulé
							</div>
							<div className="courses legend item blue" onClick={() => this.setState({filter: 'confirmed'})}>
							À venir
							</div>
							<div className="courses legend item green" onClick={() => this.setState({filter: 'done'})}>
								Effectué
							</div>
						</div>*/}
						<div className="list scroll-y hid-scroll">
							{this.props.orders?
									this.renderOrders()
								:
									<ScaleLoader
											className={' loader'}
											sizeUnit={"px"}
											height={30}
											width={5}
											color={'#4080ff'}
											loading={true}
										/>}
						</div>

					</div>
			</React.Fragment>
		)
	}
}
