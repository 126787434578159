import React from 'react'
import './article_pepite.css'
import image from './Untitled.png'

import logo from '../../files/images/logo.png'



export default function Article(props){
	return(
		<div id="article_pepite" className="container">
			<div className="dshbd-band head">
				<div className="row">
					<a className="btn transparent" href="https://expliquemoipythagore.fr">
						<img
							alt=""
							style={{width: '8vh'}}
							src={logo}/>
						</a>
						<h2 style={{color: 'white', paddingTop: 'auto', paddingBottom: 'auto', marginLeft: '20px'}}>Article: Prix National Pépite des PÉPITES</h2>
					</div>
				</div>
			<head>
				<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
				<title>Prix National Pépite des PÉPITES</title>
			</head>
			<div class="page-body">

				<article id="4cb1ccc0-1ed8-4f60-b88e-0476fdd387bf" class="page sans">
					<header>
						<h1 class="page-title">Prix National Pépite des PÉPITES</h1>
					</header>
					<div>
						<p id="f58c6276-65db-458e-955c-3718a9e24bca" class="">En tant que<strong> lauréats du </strong>
						<strong>
							<a href="https://www.u-pec.fr/fr/formation/orientation-et-insertion-professionnelle/lucas-orry-alumni-de-lupec-est-laureat-du-prix-pepites-des-pepite-2020">Prix Pépite des PEPITES 2020</a>
						</strong>, récompensant notre <strong>initiative face à la COVID-19</strong>, l&#x27;équipe d&#x27;<strong>Explique-moi Pythagore</strong> a eu le privilège de rencontrer madame <strong>Frédérique Vidal</strong>, ministre de l&#x27;Enseignement supérieur, de la Recherche et de l&#x27;Innovation.</p>
						<p id="a6ba7a53-0f1e-47dd-b6b4-b276bff08895" class="">
						</p>
						<figure id="e84417d0-a4bc-451a-bdd6-65437138e7e4" class="image">
							<a href="Prix%20National%20Pe%CC%81pite%20des%20PE%CC%81PITES%20e84417d0a4bc451abdd665437138e7e4/Untitled.png">
							<img style={{width:'1600px'}} alt="" src={image}/>
							</a>
						</figure>
						<p id="be5ed502-3a6f-4815-a7ca-2a0a7a9d7434" class="">Corentin ORRY, président d&#x27;Explique-moi Pythagore et madame Frédérique Vidal, ministre de l&#x27;enseignement supérieur.</p>
						<p id="e8c40713-5867-45d1-b5d1-38a2c56676d5" class="">
						</p>
						<h2 id="949ba5f2-7800-457b-8d5f-69c1d61e98d6" class="">La transmission du savoir...</h2>
						<p id="b2b69df0-d2cd-4fbd-bb15-9c3eb62ac76e" class="">
						</p>
						<p id="5fee986a-8353-4671-8ea9-11fd38306368" class="">« Le carré de l’hypoténuse est égal à la somme des carrés des deux autres côtés ». Cela vous dit peut-être quelque chose ? Il s’agit d’un théorème vieux de plus de 2 000 ans, mis en lumière par <strong>Pythagore</strong>. En effet, ce savoir a traversé les années, les siècles puis les millénaires et perdure encore. Notre volonté d’entreprendre fut inspirée par cette idée de transmission du savoir.</p>
						<p id="609efae8-68e5-4747-a5c5-cb5ac6c2aacb" class="">Cependant, cette transmission fut perturbée, pour cause de virus venant d’Asie, obligeant quelques 15 millions d’écoliers, collégiens et lycéens à devoir apprendre à la maison. Les professeurs sont à saluer : leur dévouement à accompagner leurs élèves fut remarquable. Mais leur seule volonté ne suffisait pas, comme le démontre le décrochage scolaire subi par environ 500 000 élèves.</p>
						<p id="6da66ade-fcbf-48a4-aa4f-e5312c736e2f" class="">Face à cela, nous avons voulu <strong>participer, à notre niveau, à cette solidarité nationale</strong> qui s’est installée, en proposant un service de cours particuliers à distance <strong>dépourvu de tout intérêt mercantile</strong>.</p>
						<h2 id="92758b57-98fa-4a76-9b6d-9c854aa7145f" class="">Prix Pépites : Un rappel de nos valeurs</h2>
						<p id="a33ee2c9-785f-41ba-8b14-024579cc81af" class=""></p>
						<p id="6712bf12-edc8-433c-b350-4a8a1a009062" class="">Pour cette initiative face à la COVID-19. Nous avons eu le privilège de rencontrer la ministre de l’Enseignement supérieur, madame Frédérique Vidal, au sein de son ministère.</p>
						<p id="19faa49f-cc85-4ae9-aa4f-167d08be3ee3" class="">Notre entreprise émane tant de notre volonté d’entreprendre que de celle de la France à soutenir l’initiative entrepreneuriale. La CCI, l’université de Créteil ou encore la région Ile-de-France, ont pu nous aider sous diverses formes et continuent de nous soutenir. Par notre action, nous avons voulu redonner à la France ce qu’elle nous a donné, en permettant à nos élèves de <strong>poursuivre une éducation scolaire à la maison</strong>.</p>
						<p id="b075043f-5c6f-4099-ba65-c3f66bf5ad04" class="">Nous sommes fiers d’avoir pu participer à ce formidable élan de solidarité face à cette crise. Nous tenons à saluer les nombreuses personnes et entreprises qui ont œuvré dans ce sens. Egalement, nous saluons nos &quot;Camarades de promo&quot; pour cette édition 2020 des Prix Pépites.</p>
						<p id="71a72da3-84ee-4a7f-a1f8-ca57c191d48b" class=""></p>
					</div>
				</article>
			</div>
		</div>
)
}
