import React from 'react'
import Calendar from 'react-calendar'

import { formating, getTime, add_minutes } from '../functions/functions'

import TimePicker from './TimePicker'
import RecurrentCourse from './RecurrentCourse'

import IosInformationCircle from 'react-ionicons/lib/IosInformationCircle'

import '../css/date-time-picker.css'


export default class DateTime extends React.Component{
	constructor(props){
		super(props)
		this.state={
			date: undefined,
			time: '',
			duration: 1,
			endDate: null,
		}
	}

	componentDidMount(){
		if(this.props.date){
			this.setState({date: this.props.date, time: getTime(this.props.date, "string")})
		}else{
			let newTime = getTime(add_minutes(new Date(), 1*60).setMinutes(0), "string") //arrondi à l'heure au dessus
			this.setState({date: undefined, time: newTime})
		}
	}

	onChange = date => {
		this.setState({date: date}, this.createDate)
	}

	onTimeChange = time => {
		this.setState({time: time.hour.toString()+':'+time.minute.toString(), duration: time.duration}, this.createDate)
	}

	createDate = () => {
		let d = new Date(this.state.date)
		const year = d.getFullYear().toString()
		const month = (d.getMonth()+1).toString() //month is from 00 to 11
		const day = d.getDate().toString()
		const hour = this.state.time[0]+this.state.time[1]
		const minute = this.state.time[3]+this.state.time[4]
		let date = year+'-'
		+formating(month)+'-'
		+formating(day)+'T'
		+formating(hour)+':'
		+formating(minute)+'+'
		+formating((-d.getTimezoneOffset()/60).toString())+':00'
		this.props.giveDate(date)
		this.props.giveDuration(this.state.duration)
	}

	validateEndDate(endDate){
		this.setState({showRecurrent: false, endDate})
		this.props.giveEndDate(endDate)
	}

	tileClassName = ({ date, view }) => {
		// Add class to tiles in month view only
		if (view === 'month') {
			// Check if a date React-Calendar wants to check is on the list of dates to add class to
			if (date.getDate() === new Date().getDate() && date.getMonth() === new Date().getMonth()) {
				return 'today-tile';
			}else if ( this.state.date && (date.getDate() === new Date(this.state.date).getDate() && date.getMonth() === new Date(this.state.date).getMonth())) {
				return 'chosen-date-tile';
			}
		}
	}

	render(){
		return(
			<div className={this.props.style? this.props.style:"date-time-picker fixed"}>
				<Calendar
					onChange={this.onChange}
					minDate={new Date()}
					tileClassName={this.tileClassName}
				/>
				{!this.props.noTime && this.state.date &&
					<TimePicker
						time={this.state.time}
						onTimeChange={this.onTimeChange}/>
					}
					{this.props.withRecurrent && this.state.date ?
						<button className={this.state.endDate? "btn lg orderer-select blue":"btn lg orderer-select yellow outline"} onClick={() => {this.setState({showRecurrent: true})}}>
							Programmer plusieurs cours
						</button>
						:
						<small>
							<IosInformationCircle
							fontSize="1.4em"
							color="#fa0"
							style={{position: 'relative', top: 4}}/>
							Sélectionnez une <strong>date</strong> et un <strong>profil d'élève</strong> pour <br/>	pouvoir accéder à la récurrence</small>
					}
					{this.props.toggle &&
						<button
                            className={!(this.state.date && this.state.date)? "btn lg yellow orderer-select":"btn lg green orderer-select"}
                            id="level"
                            onClick={this.props.submit? () => {this.props.submit(); this.props.toggle()}: () => this.props.toggle()}>
							{!this.state.date? "Fermer":(this.props.submitText? this.props.submitText:"Valider")}
						</button>
					}
                    {this.props.submit && <button className="btn lg yellow orderer-select" onClick={() => this.props.toggle()}>Annuler </button>}
					{this.state.showRecurrent &&
						<RecurrentCourse
							startDate={this.state.date}
							hour={this.state.time}
							endDate={this.state.endDate}
							validate={(endDate) => this.validateEndDate(endDate)}
							close={() => this.setState({showRecurrent: !this.state.showRecurrent})}
                        />
					}
					</div>
				)
			}
		}
